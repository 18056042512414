import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, MatButtonModule, RouterLink]
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];

  constructor() { }

  ngOnInit() { }

  public getBanner(index: any){
    return this.banners[index];
  }

  public getBgImage(index: any){
    let bgImage = {
      'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage;
  } 

}
