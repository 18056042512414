import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgIf, NgFor } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { isPlatformBrowser } from '@angular/common';
import { Product, cartitem } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/auth.service';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { RatingComponent } from '../rating/rating.component';

@Component({
    selector: 'app-mobile-product',
    templateUrl: './mobile-product.component.html',
    styleUrls: ['./mobile-product.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, FormsModule, DecimalPipe, RatingComponent]
})
export class MobileProductComponent implements OnInit {

  @Input('products') products: Array<any> = [];
  public config: SwiperConfigInterface = {};
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  public settings: Settings;
  public itemsPerPage:number = 24;
  public count:number = 1;
  public value: number = 4;
  public max: number = 5;
  public page:any;
  public disabled: boolean = false;
  public dense: boolean = true;
  public readonly: boolean = true;
  public color: ThemePalette = 'warn';
  constructor(public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object, public authService: AuthenticationService,public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router) { 
    this.settings = this.appSettings.settings;
    this.authService?.currency$.subscribe((currency: any) =>{
      this.currency=currency
   })
   console.log("products", this.products)
  }

  ngOnInit() { }
  
  ngAfterViewInit(){

  }

  

  
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')!); 
 public tempsEcoule = (date: string) => {
  let temps = "58 min";
  const currentDate = new Date();
  const current_year = currentDate.getFullYear();
  const current_month = currentDate.getMonth() + 1;

  const oldDate = new Date(date);
  const old_year = oldDate.getFullYear();
  const old_month = oldDate.getMonth() + 1;

  if (this.language != null) {
    if (current_year >= old_year) {
      if (this.language == "chn") {
        temps = (current_year - old_year + 1).toString() + " 年";
        return temps;
      } else if (this.language == "fr") {
        temps = (current_year - old_year + 1).toString() + " an(s)";
        return temps;
      } else {
        temps = (current_year - old_year + 1).toString() + " year(s)";
        return temps;
      }
    }
  } else {
    if (current_year >= old_year) {
      temps = (current_year - old_year + 1).toString() + " year";
      return temps;
    }
  }

  // Add a default return statement here
  return temps; // You can return the default value or handle it differently based on your requirements.
};


  public truncate(str: any, maxlength: any) {
    return (str.length > maxlength) ?
      str.slice(0, maxlength - 1) + '…' : str;
  }
  
  public removeSlash(name: string){
    return name.split(' ').join("-")
    
  }

  public onPageChanged(event: any){
    this.page = event;
    // this.getfeatured(); 
    // if (isPlatformBrowser(this.platformId)) {
    //   window.scrollTo(0,0);
    // } 
  }

  public addToCart(product: Product): boolean {
  let currentProduct = this.appService.Data.cartList.find(item => item?.product_id?.id === product.id);

  if (currentProduct) {
    if ((currentProduct.cartCount + this.count) <= product?.stock) {
      product.cartCount = currentProduct.cartCount + this.count;
    } else {
      this.snackBar.open('You can not add more items than available. In stock ' + product?.stock + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      return false;
    }
  } else {
    product.cartCount = this.count;
  }

  this.appService.addToCart(product);

  return true; // Add this line to indicate successful addition to the cart
}
}