import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import("./auth/auth.routes")
    },
  {
      path: '',
      loadComponent: () => import("./pages/pages.component").then(module => module.PagesComponent),
      children: [
            
          {
              path: '',
              loadChildren: () => import("./pages/home/home.routes")
          },
          {
              path: 'tracking/:number',
              title: "Tracking - Markpedia - China Africa Trade Assurance",
              loadComponent: () => import("./pages/tracking/tracking-details/tracking-details.component").then(module => module.TrackingDetailsComponent),
          },
          {
              path: 'membership',
              loadComponent: () => import("./pages/membership/membership.component").then(module => module.MembershipComponent),
          },
          {
              path: 'tracking',
              title: "Tracking - Markpedia - China Africa Trade Assurance",
              loadComponent: () => import("./pages/tracking/tracking.component").then(module => module.TrackingComponent),
          },
          {
              path: 'dispute',
              loadComponent: () => import("./spage/dispute/dispute").then(module => module.DisputeComponent),
          },
          {
              path: 'compare',
              loadComponent: () => import("./pages/compare/compare.component").then(module => module.CompareComponent)
          },
          {
              path: 'wishlist',
              loadComponent: () => import("./pages/wishlist/wishlist.component").then(module => module.WishlistComponent)
          },
          {
              path: 'cart',
              loadComponent: () => import("./pages/cart/cart.component").then(module => module.CartComponent)
          },
          {
              path: 'checkout',
              loadComponent: () => import("./pages/checkout/checkout.component").then(module => module.CheckoutComponent)
          },
          {
              path: 'contact',
              loadComponent: () => import("./pages/contact/contact.component").then(module => module.ContactComponent)
          },
          {
              path: 'brands',
              loadChildren: () => import("./pages/brands/brands.routes")
          },
          {
              path: 'products',
              loadChildren: () => import("./pages/products/products.routes")
          },
          {
              path: 'category',
              loadComponent: () => import("./pages/category/category.component").then(module => module.CategoryComponent)
          },
          {
              title: "About us - Markpedia",
              path: 'about',
              loadComponent: () => import("./pages/about/about.component").then(module => module.AboutComponent)
          },
          {
            title: "FAQ - Markpedia",
              path: 'faq',
              loadComponent: () => import("./pages/faq/faq.component").then(module => module.FaqComponent)
          },
          {
              path: 'blog/:id/:title',
              loadComponent: () => import("./pages/blog-detail/blog-detail.component").then(module => module.BlogDetailComponent)
          },
          {
              title: "Blog - Markpedia",
              path: 'blog',
              loadComponent: () => import("./pages/articles/articles.component").then(module => module.ArticlesComponent)
          },
          {
              path: 'logistic-service',
              loadComponent: () => import("./pages/logistic-service/logistic.component").then(module => module.LogisticComponent)
          },
          {
              path: 'sell-on-markpedia',
              loadComponent: () => import("./pages/sell-on-markpedia/sell.component").then(module => module.SellComponent)
          },
          {
              path: 'product',
              loadComponent: () => import("./pages/product/product.component").then(module => module.ProductComponent)
          },
          {
              path: 'static',
              loadComponent: () => import("./pages/static-page/static.component").then(module => module.StaticComponent)
          },
          {
              path: 'spage',
              loadChildren: () => import("./spage/spage.routes")
          },
      ]
  }
];
