<!-- <div *ngIf="articles" >
  <div class="list-blog">
    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let post of articles | slice: 0:4 ">
            <div class="item-inner box">
                <div class="top-block">
                    <a [routerLink]="['/blog',post?.id, removeSlash(post?.title)]">
                    <img class="content imgBox" [src]="post.avatar" width="100%" height="200px" alt="">
                    <img class="icon " src="./assets/image/theme/icons/blog.png" width="100%" height="300px" alt="{{post?.title}}">
                    </a>
                    <div class="date-blog">
                        <div class="day-time">{{post.modified_time | date : 'd'}}</div>
                        <div class="mon-time">{{post.modified_time | date : 'MMM'}}</div>
                    </div>

                    <div class="entry-meta" style="display: flex; justify-content: space-between; margin-top: 10px;">
                        <div class="entry-date" style="color: #A10710;"><i class="fa fa-calendar"></i> <span style="color: #777; margin-left: 10px;">{{post.modified_time| date : 'longDate'}}</span></div>
                        <div class="entry-comment" style="color: #A10710;"><i class="fa fa-eye"></i> <span style="color: #777; margin-left: 10px;">{{post.views}} {{'_HOME.VIEWS_'|translate}}</span></div>
                    </div>
                   
                </div>
                <div class="" style="text-align: left;">
                    <h4 style="text-align: left; font-weight: bold;"><a [routerLink]="['/blog',post?.id, removeSlash(post?.title)]" style="text-align: left; color: #000000;">{{post.excerpt}}</a></h4>
                </div>
             
            </div>
        </div>
        
    </div>
</div>
</div> -->

<div *ngIf="articles" class="container pt-5">
    <div class="list-blog">
      <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let post of articles ">
              <div class="item-inner box">
                  <div class="top-block">
                      <a [routerLink]="['/blog',post?.id, removeSlash(post?.title)]">
                      <img class="content imgBox" [src]="base_url + post?.cover[0]?.url" width="100%" height="200px" alt="{{post?.cover[0]?.alternativeText}}">
                      <!-- <img class="icon " src="assets/image/theme/icons/blog.png" width="100%" height="300px" alt="{{post?.title}}"> -->
                      </a>
                      <!-- <div class="date-blog">
                          <div class="day-time">{{post.created_at | date : 'd'}}</div>
                          <div class="mon-time">{{post.created_at | date : 'MMM'}}</div>
                      </div> -->
  
                      <div class="entry-meta" style="display: flex; justify-content: space-between; margin-top: 10px;">
                          <div class="entry-date" style="color: #A10710;"><i class="fa fa-calendar"></i> <span style="color: #777; margin-left: 10px;">{{post.created_at| date : 'longDate'}}</span></div>
                          <div class="entry-comment" style="color: #A10710;"><i class="fa fa-eye"></i> <span style="color: #777; margin-left: 10px;">{{post.views}} {{'_HOME.VIEWS_'|translate}}</span></div>
                      </div>
                      <div class="entry-meta" style="display: flex; justify-content: space-between; margin-top: 10px;">
                        <div class="entry-date" >
                            <span style="font-weight: bold;">By</span> &nbsp;<span style="color: #A10710;">{{post?.author.username}}</span>
                            </div>
                        <div class="entry-comment" style="color: #A10710; font-weight: bold;">
                            {{post?.category.title}}
                            </div>
                    </div>
                  </div>
                  <div class="" style="text-align: left;">
                      <h4 style="text-align: left; font-weight: bold;"><a [routerLink]="['/blog',post?.id, removeSlash(post?.title)]" style="text-align: left; color: #000000;">{{post.title}}</a></h4>
                  </div>
                  
              </div>
          </div>
          
      </div>
  </div>
  </div>