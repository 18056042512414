<div *ngIf="products">
  <div class="module so-listing-tabs-ltr listingtab-layout3" >
    <div class="modcontent">
        <div id="so_listing_tabs_2" class="so-listing-tabs first-load">
          
            
            <div class="ltabs-wrap">
             
                <div class="ltabs-items-container products-list grid">
        
                    <div class="ltabs-items ltabs-items-selected items-category-31" data-total="31">
                        <div class="ltabs-items-inner ltabs-slider dealsdayblock row" >
                            <div class="col-item dealsdaycard" *ngFor="let dealsdays of products | slice:0:21" > 
                                <div class="ltabs-item" >
                                    <div class="item-inner product-layout transition product-grid">
                                        <div class="product-item-container">
                                            <div class="left-block">
                                                <!-- <span class="label-product label-product-sale">-{{dealsdays?.product_id.discount}}%</span> -->
                                                <div class="product-image-container second_img catbox-content">
                                                    <a routerLink="/products/{{dealsdays?.product_id.my_product_flat.id}}/{{removeSlash(dealsdays?.product_id?.my_product_flat?.name)}}" target="_self" title="{{dealsdays?.product_id?.my_product_flat?.name}}">
                                                    <img src="{{dealsdays?.product_id.images?.path}}" class="img-1 img-responsive"   alt="{{dealsdays?.product_id?.my_product_flat?.name}}">
                                                    <img src="{{dealsdays?.product_id.images2?.path}}" class="img-2 img-responsive"   alt="{{dealsdays?.product_id?.my_product_flat?.name}}">
                                                    </a>
                                                </div>
                                                <div class="button-group so-quickview cartinfo--left">
                                                    <button class="btn-button addToCart" title="Add to Cart" type="button" (click)="addToCart(dealsdays?.product_id)"><i class="fa fa-shopping-basket"></i>  <span> {{'_HOME.ADD_TO_CART'|translate}} </span>
                                                    </button>
                                                    <button class="btn-button wishlist" type="button" title="Add to Wish List" (click)="addToWishList(dealsdays?.product_id)"><i class="fa fa-heart"></i><span>{{'_HOME.ADD_TO_WISH_LIST'|translate}}</span>
                                                    </button>
                                                    <button class="btn-button compare" type="button" title="Compare this Product" (click)="addToCompare(dealsdays?.product_id)"><i class="fa fa-refresh"></i><span>{{'_HOME.COMPARE_THIS_PRODUCT'|translate}}</span>
                                                    </button>
                                                                                                   
                                                    
                                                </div>
                                            </div>
                                            <div class="text-center">
                                                <div class="">
                                                   
                                                    <a routerLink="/products/{{dealsdays?.product_id.my_product_flat.id}}/{{removeSlash(dealsdays?.product_id?.my_product_flat?.name)}}" title="{{dealsdays?.product_id?.my_product_flat?.name}}" target="_self" style="text-overflow: ellipsis;color: black; font-weight: bold;"> {{truncate(dealsdays?.product_id.my_product_flat.name, 37)}} </a>
                                                  
                                                    <div  class="card-footer">
                                                        <div class="wcf-left">
                                                            <app-rating [rating]="3.5"></app-rating>
                                                        </div>

                                                          <div class="wcf-right">
                                                            {{tempsEcoule(dealsdays?.product_id?.created_at)}}
                                                          </div>
                                                    </div>
                                                    
                                                    <p class="price">{{currency?.symbol}} {{dealsdays?.dealy_deals_price* (currency?.value || 1)  | number : '1.1-2' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                           
                        </div>
                        
                    </div>
                  
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>