<div *ngIf="products" class="so-extraslider">
    <div class="yt-content-slider extraslider-inner products-list" data-rtl="yes" data-pagination="yes" data-autoplay="no" data-delay="4" data-speed="0.6" data-margin="0" data-items_column0="2" data-items_column1="1" data-items_column2="1" data-items_column3="1" data-items_column4="1" data-arrows="no" data-lazyload="no" data-loop="no" data-buttonpage="top" style="display: flex;">
        <div class="item col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let deal of products | slice:0:3; let index = index">
            <div style="background-color: #fff; padding: 20px; max-height: 400px; height: 400px;" class="product-thumb transition">
                <div class="row">
                    <div class="inner">
                        <div class="item-left col-lg-6 col-md-5 col-sm-5 col-xs-12">
                            <div class="image">
                                <!-- <span class="label-product label-product-sale">- {{deal?.deals.product_id.discount}} %</span> -->
                                <a routerLink="/products/{{deal?.deals.product_id.id}}/{{removeSlash(deal?.deals.product_id.my_product_flat.name)}}" target="_self">
                                <img src="{{deal?.deals.product_id.images?.path}}" alt="product" class="img-responsive" width="300">
                                </a>
                                <div class="button-group so-quickview">
                                    <button class="btn-button addToCart" title="Add to Cart" type="button" (click)="addToCart(deal?.deals.product_id)"><i class="fa fa-shopping-basket"></i>  <span> {{'_HOME.ADD_TO_CART'|translate}} </span>
                                    </button>
                                    <button class="btn-button wishlist" type="button" title="Add to Wish List" (click)="addToWishList(deal?.deals.product_id)"><i class="fa fa-heart"></i><span>{{'_HOME.ADD_TO_WISH_LIST'|translate}}</span>
                                    </button>
                                    <button class="btn-button compare" type="button" title="Compare this Product" (click)="addToCompare(deal?.deals.product_id)"><i class="fa fa-refresh"></i><span>{{'_HOME.COMPARE_THIS_PRODUCT'|translate}}</span>
                                    </button>
                                                                                   
                                    <a class="iframe-link btn-button quickview quickview_handler visible-lg" routerLink="/products/{{deal?.deals.product_id.id}}/{{removeSlash(deal?.deals.product_id.my_product_flat.name)}}" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>{{'_HOME.QUICK_VIEW'|translate}}</span></a>                                                        
                                    
                                </div>
                            </div>
                        </div>
                        <div class="item-right col-lg-6 col-md-7 col-sm-7 col-xs-12">
                            <div class="" style="width: 100%;">
                                <a  routerLink="/products/{{deal?.deals.product_id.id}}/{{removeSlash(deal?.deals.product_id.my_product_flat.name)}}" target="_self" title="product" style="text-overflow: ellipsis; color: black; font-weight: bold;"> {{truncate(deal?.deals.product_id.my_product_flat.name, 36)}} </a>
                                

                                <div  class="card-footer">
                                    <div class="wcf-left">
                                        <app-rating [rating]="3.5"></app-rating>
                                    </div>

                                      <div class="wcf-right">
                                        {{tempsEcoule(deal?.deals?.product_id?.created_at)}}
                                      </div>
                                </div>
                                <!-- <div  class="card-footer">
                                    <div class="wcf-left">
                                        <p class="price mt-5" > <span class="price-old">{{currency?.symbol}} {{deal?.deals.product_id.price* (currency?.value || 1) | number : '1.1-2'  }}</span> <br> {{currency?.symbol}} {{deal?.deals.dealy_deals_price* (currency?.value || 1)  | number : '1.1-2' }} <span class="price-new"></span>
                                        </p>
                                    </div>

                                      <div class="wcf-right">
                                        <p class="col-xs-6 a1">{{'_HOME.AVAILABLE_'|translate}}: <b> {{deal?.deals.dealy_deals_stock}}</b> 
                                        </p>
                                      </div>
                                </div> -->
                                
                               
                               
                                <div class="item-available">
                                    <p class="price mt-5" > <span class="price-old">{{currency?.symbol}} {{deal?.deals.product_id.price* (currency?.value || 1) | number : '1.1-2'  }}</span> {{currency?.symbol}} {{deal?.deals.dealy_deals_price* (currency?.value || 1)  | number : '1.1-2' }} <span class="price-new"></span>
                                    </p>
                                    <p class="a1">{{'_HOME.AVAILABLE_'|translate}}: <b> {{deal?.deals.dealy_deals_stock}}</b> 
                                    </p>
                                    <!-- <div class="row">
                                        
                                        <p class="col-xs-6 a2">{{'_HOME.SOLD_'|translate}}: <b>{{deal?.deals.product_id.stock}}</b> 
                                        </p>
                                    </div>
                                    <div class="available"> <span class="color_width" data-title="40%" data-toggle='tooltip' style="width: 60%"></span>
                                    </div> -->
                                </div>
                               
                                <div class="item-time-w">
                                    <div class="time-title">{{'_HOME.HURRY_UP'|translate}}:</div>
                                    <div>
                                        <countdown [config]="deal?.config" class="custom-style"></countdown>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
        </div>
        
        
    </div>
</div>