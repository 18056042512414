<div class="footer">
    <div class="footer-top">
        <div class="footer-top-box">
            <div class="footer-top-content">
                <div class="footer-top-item">
                    <div class="footer-top-item-img">
                        <img src="assets/images/new/footer/email.svg" alt="">
                    </div>
                    <div class="footer-top-item-value">
                        <h6>EMAIL SUPPORT</h6>
                        <p>info@markpedia.com</p>
                    </div>
                </div>
                <div class="footer-top-item">
                    <div class="footer-top-item-img">
                        <img src="assets/images/new/footer/open.svg" alt="">
                    </div>
                    <div class="footer-top-item-value">
                        <h6>Availability</h6>
                        <p>Open time: 8:00AM - 6:00PM</p>
                    </div>
                </div>
                <!-- <div class="footer-top-item">
                    <div class="footer-top-item-value">
                        <h6>GET LATEST DEALS</h6>
                        <p>Our best promotions sent to your inbox.</p>
                    </div>
                </div> -->
                <div class="footer-top-item">
                    <input type="text" placeholder="Enter your email">
                    <button>Join us 
                        <img src="assets/images/new/arrow-right.svg" alt="">
                    </button>
                </div>
            </div>
        </div>
        
    </div>

    <div class="footer-bottom">
        <div class="footer-bottom-box">
            <div class="footer-bottom-content">
                <div class="footer-bottom-item">
                    <img src="assets/images/markpedia-white.svg" class="logo-img" alt="Markpedia Logo">
                    <h3>MAKE MONEY ON MARKPEDIA</h3>
                    <p>Become a MARKPEDIA Affiliate</p>
        
                    <div class="footer-bottom-item-flags">
                        <img src="assets/images/new/footer/flags/cm.svg" alt="">
                        <img src="assets/images/new/footer/flags/ch.svg" alt="">
                        <img src="assets/images/new/footer/flags/nigeria.svg" alt="">
                        <img src="assets/images/new/footer/flags/4.svg" alt="">
                        <img src="assets/images/new/footer/flags/5.svg" alt="">
                    </div>
                    <div class="footer-bottom-item-flags">
                        <img src="assets/images/new/footer/flags/6.svg" alt="">
                        <img src="assets/images/new/footer/flags/7.svg" alt="">
                        <img src="assets/images/new/footer/flags/8.svg" alt="">
                        <img src="assets/images/new/footer/flags/9.svg" alt="">
                        <img src="assets/images/new/footer/flags/10.svg" alt="">
                    </div>
        
                </div>
                <div class="footer-bottom-item">
                    <h3>Important links</h3>
    
                    <div class="footer-bottom-item-links">
                        <a routerLink="/">Home</a>
                        <a routerLink="/blog">Blog</a>
                        <a routerLink="/tracking">Tracking services</a>
                        <a routerLink="/logistic-service" >Logistic Service</a>
                        <a href="https://www.sell.markpedia.com" target="_blank">Sell On Markpedia</a>
                        <a href="https://job.markpedia.com/" target="_blank">Employment opportunity</a>
                    </div>
                    
                </div>
                <div class="footer-bottom-item">
                    <h3>BUYING ON MARKPEDIA</h3>
                    <div class="footer-bottom-item-links">
                        <a routerLink="/about">About Us</a>
                        <a routerLink="/faq">FAQ's</a>
                        <a routerLink="/spage/terms-and-conditions">Terms and Conditions</a>
                        <a routerLink="/spage/privacy-plicies">Privacy policies</a>
                        <a routerLink="/contact">Contact</a>
                    </div>
                    
                </div>
                <div class="footer-bottom-item">
                    <h3>MORE INFO</h3>
                    <div class="footer-bottom-item-links">
                        <a href="javascript:void(0)" (click)="goToLogin()">Submit a dispute</a>
                        <a routerLink="/spage/return-policy">Return & Refund Policy</a>
                    </div>
                    
                </div>
                <div class="footer-bottom-item">
                    <h3>DOWNLOAD & CONNECT WITH US</h3>
        
                    <img src="assets/images/new/footer/apple.svg" alt="">
                    <img src="assets/images/new/footer/google.svg" alt="">
        
                    <h3>CONNECT WITH US</h3>
                    <a href="https://www.instagram.com/markpedia_cameroon/" target="_blank">
                        <img src="assets/images/new/footer/instagram.svg" alt="">
                    </a>
                    <!-- <a routerLink="/">
                        <img src="assets/images/new/footer/twitter.svg" alt="">
                    </a> -->
                    <a  href="https://www.linkedin.com/company/markpedier/mycompany/" target="_blank">
                        <img src="assets/images/new/footer/linkedin.svg" alt="">
                    </a>
                    <a href="https://www.facebook.com/markpediagroup" target="_blank">
                        <img src="assets/images/new/footer/facebook.svg" alt="">
                    </a>
                </div>
            </div>
        </div>
        
        
    </div>


    <div class="footer-sub-bottom">
        <div class="footer-sub-bottom-box">
            <div class="footer-sub-bottom-content">
                <div class="footer-sub-bottom-content-left">
                    <h4>© Copyright {{currentYear}}, <span>Markpedia</span></h4>
                </div>
                <div class="footer-sub-bottom-content-right">
                    <div class="paiement-method">
                        <img src="assets/images/orange-money.jpg"  alt=""> 
                        <img src="assets/images/mtn_.png"   alt="">
                        <!-- <img src="assets/images/eu_.png"  alt=""> &nbsp; -->
                        <img src="assets/images/visa_.png"   alt=""> 
                        <img src="assets/images/master_.png"  alt=""> 
                        <img src="assets/images/unionpay.png"  alt=""> 
                        <img src="assets/images/american-express.png"  alt="">
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>


<div class="bottom-nav">
    <a class="nav-item item-home active" routerLink="/" [routerLinkActive]="['active']" [routerLinkActiveOptions]={exact:true}>
        <i class="fa-solid fa-house"></i><span>Home</span>
    </a>
    
    <a class="nav-item item-cart"  routerLink="/cart"  [routerLinkActive]="['active']">
        <i  class="fa-solid fa-cart-shopping"></i><span>Cart</span>
    </a>
    
    <a class="nav-item item-checkout" routerLink="/checkout"  [routerLinkActive]="['active']">
        <i class="fa-solid fa-bag-shopping"></i><span>Checkout</span>
    </a>
    
    <a class="nav-item item-track" routerLink="/tracking"  [routerLinkActive]="['active']">
        <i class="fa-solid fa-location-dot"></i><span>Tracking</span>
    </a>
  
</div>