import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-footer-1',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [FormsModule, RouterLink, TranslateModule, RouterOutlet, RouterModule]
})
export class FooterComponent implements OnInit {


  public currentYear = new Date().getFullYear();
  constructor(public router:Router,) { }

  ngOnInit(): void {
  }
 
  goToLogin(){
    const userProfile = JSON.parse(localStorage.getItem("markpediausersessionid")!); 
    if (userProfile == null) {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: "dispute" } })
    }else{
      this.router.navigate(['/dispute'])
    }
    
  }

  activeLink(select_item: string){
  let navItems = document.querySelectorAll(".nav-item");

  navItems.forEach(function(e, i) {
    e.addEventListener("click", function(e) {
      navItems.forEach(function(e2, i2) {
        e2.classList.remove("active");
      });
      
    });
  });
  setTimeout(()=> {
    let item = document.querySelector("."+select_item);
    item!.classList.add("active");
  }, 500)
}

}
