<!-- <div class="main-slider" *ngIf="!loading_slides">
  <div class="swiper-container h-100" >
    <div class="swiper-wrapper h-100">      
      <div  class="swiper-slide">
        <div [attr.data-background]="slide.image" class="slide-item swiper-lazy" routerLink="/products/category/5/Consumer%20Electronics">
             <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div> 

             <div class="">
              <a class="mask" routerLink="/products/category/5/Consumer%20Electronics"></a>  
             </div>
           
          
          </div>
      </div>
    </div>
    <div class="swiper-pagination white"></div>
    <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
</div>

< -->
<div>
  <div *ngIf="slides.length > 0">
    <ngb-carousel  >
      <ng-template ngbSlide >
        <div class="wrapper">
          <img [src]="slides[0].image" alt="Home Slide Product">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <p> Angular 13 Carousel Example </p>
        </div> -->
      </ng-template>
      <ng-template ngbSlide >
        <div class="wrapper">
          <img [src]="slides[1].image" alt="Home Slide Product">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <p> Angular 13 Carousel Example </p>
        </div> -->
      </ng-template>
      <ng-template ngbSlide >
        <div class="wrapper">
          <img [src]="slides[2].image" alt="Home Slide Product">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <p> Angular 13 Carousel Example </p>
        </div> -->
      </ng-template>
      <ng-template ngbSlide >
        <div class="wrapper">
          <img [src]="slides[3].image" alt="Home Slide Product">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <p> Angular 13 Carousel Example </p>
        </div> -->
      </ng-template>
      <ng-template ngbSlide >
        <div class="wrapper">
          <img [src]="slides[4].image" alt="Home Slide Product">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <p> Angular 13 Carousel Example </p>
        </div> -->
      </ng-template>
    </ngb-carousel>
  </div>
  
  
  <div  *ngIf="slides.length == 0">
    
    <div class="item top-loader-slides" >
                          
    </div>
  </div>
</div>