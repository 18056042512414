<h1 mat-dialog-title class="text-center">  <i class="fa fa-search"></i> SEARCH PRODUCTS BY IMAGE</h1>

    <div mat-dialog-content>
        <div fxLayout="row wrap">
           
         

          <div class="file-input">
            <input type="file" id="file" class="file" formControlName="image" (change)="onFileChange($event)" >
            <label for="file">
              Select image on your computer
              <p class="file-name"></p>
            </label>
            <img id="blah" [src]="imageSrc" alt="your image"  width="400" height="400"/>
         </div>
          
         <!-- <div class="">
            <label for="validationDefaultUsername">Tracking number</label>
            <div class="input-group">
             
              <input type="text" class="form-control" style="width: 100%;" placeholder="enter your tracking number">
            </div>
          </div>
            
        </div> -->
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100"> 
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" (click)="gotoTrack()" type="submit"><i class="fa fa-search"></i> Search</button>
        </div> 
    </div>
