import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   wheelPropagation: true,
//   suppressScrollX: true               
// };


import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LangComponent } from './lang/lang.component';
import { NewProductListComponent } from './products-carousel/new-product-list/new-product-list.component';
import { FeaturedProductComponent } from './products-carousel/featured-product/featured-product.component';
import { MostViewedProductComponent } from './products-carousel/most-viewed-product/most-viewed-product.component';
// import { NgxMaterialRatingModule } from 'ngx-material-rating';
import { BlogListComponent } from './blog-list/blog-list.component';
import { NewProductsComponent } from './new-products/new-products.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import { OwlModule } from 'ngx-owl-carousel';
import { CountdownModule } from 'ngx-countdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DealsProductsComponent } from './deals-products/deals-products.component';
import { DealsProductComponent } from './deals-product/deals-product.component';
import { MobileProductComponent } from './mobile-products/mobile-product.component';
import { MobileNewProductComponent } from './mobile-new-products/mobile-new-product.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PaginationComponent } from './pagination/pagination.component';

@NgModule({
    imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    // FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    // PerfectScrollbarModule,
    // NgxMaterialRatingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    // OwlModule,
    CountdownModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    BlogListComponent,
    NewProductsComponent,
    DealsProductsComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    ConfirmDialogComponent,
    LangComponent,
    NewProductListComponent,
    FeaturedProductComponent,
    MostViewedProductComponent,
    FooterComponent,
    HeaderComponent,
    DealsProductComponent,
    MobileProductComponent,
    MobileNewProductComponent,
    PaginationComponent
],
    exports: [
    RouterModule,
    TranslateModule,
    // FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    // PerfectScrollbarModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    BlogListComponent,
    NewProductsComponent,
    DealsProductsComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    ConfirmDialogComponent,
    NewProductListComponent,
    LangComponent,
    FeaturedProductComponent,
    MostViewedProductComponent,
    NgxPaginationModule,
    // NgxMaterialRatingModule,
    FormsModule,
    ReactiveFormsModule,
    // OwlModule,
    CountdownModule,
    DealsProductComponent,
    MobileProductComponent,
    MobileNewProductComponent,
    FooterComponent,
    HeaderComponent,
    PaginationComponent
],
schemas: [NO_ERRORS_SCHEMA],
    // providers: [
    //     { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    // ]
})
export class SharedModule { }
