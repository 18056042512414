<div *ngIf="products" class="module feature-product clearfix" >
    <h3 class="modtitle"><span>Best Selling Products</span></h3>
    <div class="modcontent clearfix">
        <div class="so-product-external swiper-container" >
            <div class="yt-content-slider owl-product swiper-wrapper h-100">
                <div class="item swiper-slide col-xl-3 col-lg-3 col-md-3  col-sm-4" *ngFor="let bestsellingproduct of products">
                    <div class="item-inner product-layout transition product-item">
                        <div class="product-item-container">
                            <div class="left-block">
                                <div class="product-image-container second_img">
                                    <a [routerLink]="['/products', bestsellingproduct.product_id.id, bestsellingproduct.product_id.product_id.sku]" target="_self" title="{{bestsellingproduct.product_id.product_id.sku}}">
                                    <img [src]="bestsellingproduct.product_id.images[0].path"height="230px" class="img img-responsive swiper-lazy" alt="{{bestsellingproduct.product_id.product_id.sku}}">
                                    </a>
                                </div>
                                <div class="box-label"> <span class="label-product label-product-new">New</span>
                                </div>
                                <!--quickview--> 
                                <div class="so-quickview">                                                     
                                    <a class="btn-button quickview" matTooltip="Quick view" (click)="openProductDialog(bestsellingproduct.product_id)"><i class="fa fa-eye"></i><span>Quick view</span></a>   
                                    <button type="button" class="wishlist btn-button" matTooltip="Add to wishlist" (click)="addToWishList(bestsellingproduct.product_id)"><i class="fa fa-heart"></i>
                                    </button>
                                    <button type="button" class="compare btn-button" matTooltip="Add to compare" (click)="addToCompare(bestsellingproduct.product_id)"><i class="fa fa-refresh"></i>
                                    </button> 
                                </div>
                                <!--end quickview-->
                            </div>
                            <div class="right-block">
                                <div class="caption">
                                    <div class="rating">    
                                        <!-- <app-rating [ratingsCount]="bestsellingproduct.product_id.ratingsCount" [ratingsValue]="bestsellingproduct.product_id.ratingsValue" [direction]="'column'"></app-rating> -->
                                    </div>
                                    <h4><a [routerLink]="['/products', bestsellingproduct.product_id.id, bestsellingproduct.product_id.product_id.sku]" title="{{bestsellingproduct.product_id.product_id.sku}}" target="_self">{{bestsellingproduct.product_id.product_id.sku}}</a></h4>
                                    <div class="price">
                                        <span class="price-old"  *ngIf="bestsellingproduct.product_id.oldPrice">${{bestsellingproduct.product_id.oldPrice | number : '1.2-2'}}</span>
                                        <span class="price-new">{{currency?.symbol}} {{bestsellingproduct.product_id.price* (currency?.value || 1) | number }}</span>
                                    </div> 
                                </div>
                                <div class="button-group">
                                    <button type="button" class="addToCart btn-button" mat-icon-button matTooltip="Add to cart"  (click)="addToCart(bestsellingproduct.product_id)">
                                    <span>Add to cart </span>   
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
             </div>
             <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
        </div>
    </div>
</div>