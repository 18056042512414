import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss'],
    standalone: true,
    imports: [NgFor, MatMenuModule]
})
export class CategoryListComponent {

  @Input() categories: any;
  @Input() categoryParentId: any;
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories: any;

  constructor() { }

  public ngDoCheck() {
    if(this.categories && !this.mainCategories) {
      this.mainCategories = this.categories.filter((category: any) => category.parentId == this.categoryParentId); 
    }
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(event: any){
    this.change.emit(event);
  }

}