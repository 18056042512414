import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgIf, NgFor } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { AppService } from '../../app.service';
import { Product, cartitem } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/auth.service';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { CarouselModule, OwlOptions  } from 'ngx-owl-carousel-o';
import { RatingComponent } from '../rating/rating.component';

@Component({
    selector: 'app-products-carousel',
    templateUrl: './products-carousel.component.html',
    styleUrls: ['./products-carousel.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, FormsModule, MatButtonModule, MatIconModule, DecimalPipe, CarouselModule, RatingComponent],
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  public settings: Settings;
  public value: number = 4;
  public count:number = 1;
  public max: number = 5;
  public disabled: boolean = false;
  public dense: boolean = true;
  public readonly: boolean = true;
  public color: ThemePalette = 'warn';

  customOptions: OwlOptions = {
    loop: true,
    autoWidth: false,
    mouseDrag: false,
    touchDrag: false,
    center: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      480: {
          items: 1
        },
        740: {
          items: 2
        },
        960: {
          items: 3
        },
        1280: {
          items: 5
        },
        1500: {
          items: 6
        }
    },
    nav: true
  }

  constructor(public snackBar: MatSnackBar, public authService: AuthenticationService,public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router) { 
    this.settings = this.appSettings.settings;
    this.authService?.currency$.subscribe((currency: any) =>{
      this.currency=currency
   })
  }

  ngOnInit() { }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      width: 300,
      slidesPerView: 5,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 5
        },
        1500: {
          slidesPerView: 6
        }
      }
    }
  }

  

  public openProductDialog(product: any){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]); 
      }
    });
  }

  
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')!); 
 public tempsEcoule = (date: string) => {
  let temps = "58 min";
  const currentDate = new Date();
  const current_year = currentDate.getFullYear();
  const current_month = currentDate.getMonth() + 1;

  const oldDate = new Date(date);
  const old_year = oldDate.getFullYear();
  const old_month = oldDate.getMonth() + 1;

  if (this.language != null) {
    if (current_year >= old_year) {
      if (this.language == "chn") {
        temps = (current_year - old_year + 1).toString() + " 年";
        return temps;
      } else if (this.language == "fr") {
        temps = (current_year - old_year + 1).toString() + " an(s)";
        return temps;
      } else {
        temps = (current_year - old_year + 1).toString() + " year(s)";
        return temps;
      }
    }
  } else {
    if (current_year >= old_year) {
      temps = (current_year - old_year + 1).toString() + " year";
      return temps;
    }
  }

  // Add a default return statement here
  return temps; // You can return the default value or handle it differently based on your requirements.
};


  public truncate(str: any, maxlength: any) {
    return (str.length > maxlength) ?
      str.slice(0, maxlength - 1) + '…' : str;
  }
  
  public removeSlash(name: string){
    return name.split(' ').join("-")
    
  }

  public addToCart(product: Product): boolean {
  let currentProduct = this.appService.Data.cartList.find(item => item?.product_id?.id === product.id);

  if (currentProduct) {
    if ((currentProduct.cartCount + this.count) <= product?.stock) {
      product.cartCount = currentProduct.cartCount + this.count;
    } else {
      this.snackBar.open('You can not add more items than available. In stock ' + product?.stock + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      return false;
    }
  } else {
    product.cartCount = this.count;
  }

  this.appService.addToCart(product);

  return true; // Add this line to indicate successful addition to the cart
}
}