<div id="header" class="header-content largeScreen">
    <div class="header-top">
        <div class="header-top-box">
            <div class="header-top-left">
                <!-- <div class="header-top-item">
                    <img src="assets/images/new/header/phone.svg" alt="">
                    <span> +237 680669988</span>
                </div> -->
                <div class="header-top-item">
                    <img src="assets/images/new/header/message.svg" alt="">
                    <span> contact@markpedia.cm</span>
                </div>
                <div class="header-top-item">
                    <span class="brand">Follow Us  and get a chance to win 80% off</span>
                </div>
            </div>
            <div class="header-top-right">
                <div class="header-top-social-media">
                    <a href="https://www.instagram.com/markpedia_cameroon/" target="_blank">
                        <img src="assets/images/new/footer/instagram.svg" alt="">
                    </a>
                    <a  href="https://www.linkedin.com/company/markpedier/mycompany/" target="_blank">
                        <img src="assets/images/new/footer/linkedin.svg" alt="">
                    </a>
                    <a href="https://www.facebook.com/markpediagroup" target="_blank">
                        <img src="assets/images/new/footer/facebook.svg" alt="">
                    </a>
                </div>
    
                <div class="header-top-lang">
                    <ul class="header-top-dropdown">
                        <li class="header-top-dropdown-menu">
                            <a href="#" aria-haspopup="true">
                                <span>
                                    <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" [alt]="translateService.currentLang" >  
                                    <span *ngIf="translateService.currentLang == 'en'">
                                        English
                                    </span>
                                    <span *ngIf="translateService.currentLang == 'fr'">
                                        French
                                    </span>
                                    <span *ngIf="translateService.currentLang == 'chn'">
                                        Chinese
                                    </span> 
                                </span>
                                <i class="fa-solid fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown" aria-label="submenu">
                                <li *ngFor="let lang of translateService.getLangs()"> <a (click)="changeLang(lang)">
                                    <img [src]="'assets/images/flags/'+lang+'.svg'" width="22" [alt]="lang" >  
                                    <span *ngIf="lang == 'en'">
                                        English
                                    </span>
                                    <span *ngIf="lang == 'fr'">
                                        French
                                    </span>
                                    <span *ngIf="lang == 'chn'">
                                        Chinese
                                    </span>
                                </a></li>
                            </ul>
                        </li >
                        <li class="header-top-dropdown-menu" >
                            <a href="#" aria-haspopup="true">
                                <span>
                                   {{currency?.currency}} 
                                </span>
                                <i class="fa-solid fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown" aria-label="submenu">
                                <li *ngFor="let currencie of currencies"> <a (click)="changeCurrency(currencie)" >{{currencie?.currency}} - {{currencie?.name}}</a></li>
                            </ul>
                        </li >
                    </ul>
                    
                     <!-- <input id="toggle2" type="checkbox">
                        <label for="toggle2" class="animate">
                            <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" [alt]="translateService.currentLang" >  
                            <span *ngIf="translateService.currentLang == 'en'">
                                English
                            </span>
                            <span *ngIf="translateService.currentLang == 'fr'">
                                French
                            </span>
                            <span *ngIf="translateService.currentLang == 'chn'">
                                Chinese
                            </span>
                        </label>
                        <ul class="animate" >
                            <li class="animate" *ngFor="let lang of translateService.getLangs()"> <a (click)="changeLang(lang)">
                                <img [src]="'assets/images/flags/'+lang+'.svg'" width="22" [alt]="lang" >  
                                <span *ngIf="lang == 'en'">
                                    English
                                </span>
                                <span *ngIf="lang == 'fr'">
                                    French
                                </span>
                                <span *ngIf="lang == 'chn'">
                                    Chinese
                                </span>
                            </a></li>
                        </ul>
                         -->
                </div>
            </div>
        </div>
        
    </div>

    <div class="header-middle">
        <div class="header-middle-box">
            <div class="header-middle-content">
                <div class="header-middle-logo">
                    <a routerLink="/">
                        <img src="assets/images/new_logo.png" alt="Markpedia Logo">
                    </a>
                </div>
                <div class="header-middle-search">
                    
                    <form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
                               
                        <input class="input-search-product-file" type="file" #filePicker (change)="searchProductsOnImage($event)">

                        <div class="header-middle-search-input">
                            <input type="text" value="" 
                                formControlName="product"
                                autocomplete="off" 
                                placeholder="{{ 'SEARCH_PRODUCT' | translate }}"
                                name="search"
                                [formControl]="control"
                                [(ngModel)]="search_product"
                            >
                            <select formControlName="categorie">
                                <option selected disabled value="All Categories">All Categories </option>
                                <option *ngFor="let categorie of categories" value="{{categorie?.name}}">{{categorie?.name}}</option>
                            </select>
                            <button type="submit">
                                <img src="assets/images/new/header/search.svg" alt="">
                            </button>
                        </div>

                        <div class="header-middle-camera">
                            <div class="camera-bg" (click)="filePicker.click()"><img src="assets/images/camera-5-64.png" width="20px" height="20px"  alt=""></div>
                        </div>
                        
                                        
                    </form>
                    
                </div>
                <div class="header-middle-auth">
                    <div class="header-middle-auth-user">
                        <div class="img-profile">
                            <img src="assets/images/new/header/user.svg" alt="User profile images">
                        </div>
                        <div class="profile-content"  *ngIf="!user">
                            <a routerLink="/auth/login">Login</a>
                            <span> / </span>
                            <a routerLink="/auth/register">Join for free</a>
                        </div>
                        <div class="profile-content"  *ngIf="user">
                            <!-- <a href="javascript:void(0)" (click)="goToBuyer()">Hello, {{user?.user.first_name}}</a> -->
                            <ul class="menu">
                                <li>
                                    <a  href="javascript:void(0)" (click)="goToBuyer()">Hello, {{user?.user.first_name}}</a>
                                    <!-- Sous-menu -->
                                    <ul class="submenu">
                                        <li><a href="javascript:void(0)" (click)="goToBuyer()">My Profile</a></li><br>
                                        <li><a href="javascript:void(0)"  (click)="logout()">Logout</a></li> <br>
                                        <!-- <li><a href="#">Service 3</a></li> -->
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="header-middle-auth-cart btn-shopping-cart">
                        
                        <ul class="menu">
                            <li>
                                <a routerLink="/cart" >
                                    <img src="assets/images/new/header/cart.svg" alt="Cart item images">
                                    <span  *ngIf="user">{{appService.Data.cartList.length}}</span>
                                </a>
                                <!-- Sous-menu -->
                                <div class="submenu" *ngIf="user">
                                    <div class="header-top-cart-list">
                                        <table class="table table-striped">
                                            <tbody>
                                                <tr *ngFor="let product of cart_products">
                                                    <td class="text-center" style="width:70px">
                                                        <a style="color: #000; text-decoration: none;" routerLink="/products/{{product?.product_id?.id}}/{{removeSlash(product?.product_id?.my_product_flat.name)}}">
                                                        <img [src]="product?.product_id?.images.path" style="width:70px; height: 50px;" [alt]="product?.product_id?.my_product_flat.name" class="preview">
                                                        </a>
                                                    </td>
                                                    
                                                    
                                                    <td class="text-center">{{product.cartCount}}</td>
                                                    
                                                    <td class="text-center">{{currency?.symbol}} {{(product.cartCount || 1)*(product?.product_id?.price || 1)* (currency?.value || 1)  | number : '1.1-2' }}</td>
                                                  
                                                    
                                                    <td class="text-right">
                                                        <a (click)="remove(product)" class="fa fa-times fa-delete"></a>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="header-top-cart-list">
                                        <table class="table table-bordered">
                                            
                                            
                                        </table>
                                        <p class="text-right" style="width: 100%; display: flex; justify-content: space-between;"> 
                                            <a class="btn view-cart" style="color: #ffffff; background-color: #A10710; border: 1px solid #A10710;" routerLink="/cart"><i class="fa fa-shopping-cart"></i> &nbsp; {{'VIEW_CART'| translate}}</a>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-mega checkout-cart" style="color: #ffffff; background-color: #A10710; border: 1px solid #A10710;" routerLink="/checkout"><i class="fa fa-share"></i> &nbsp; {{'NAV.CHECKOUT'|translate}}</a> 
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-footer">
        <div class="header-footer-box">
            <div class="header-footer-content">
                <div class="header-footer-categorie">
                    <ul class="menu">
                        <li> 
                            <img src="assets/images/new/header/list.svg" alt="Categories list item">
                            Categories 
                        </li>
                        <li *ngFor="let categorie of categories | slice:4:20">
                            <h3>
                                <a routerLink="/products/category/{{categorie.id}}/{{categorie.name}}">
                                    <img [src]="categorie?.image" alt="Categories item images">
                                    {{categorie?.name}}
                                </a>
                            </h3>
                            <div>
                                <h4>{{categorie?.name}}</h4>
                                <section class="categorie-section" >
                                    <section class="categorie-item" *ngFor="let child of categorie.children | slice:0:4">
                                        <h5>{{child?.name}}</h5>
                                        <ul>
                                            <li *ngFor="let sub_categorie of child.children">
                                                <a routerLink="/products/category/{{sub_categorie.id}}/{{sub_categorie.name}}">{{sub_categorie?.name}}</a>
                                            </li>
                                        </ul>
                                    </section>
                                </section>
                                
                            </div>
                        </li>
                        
                    </ul>
                </div>
                <div class="header-footer-menu">
                    <div class="header-footer-menu-content">
                        <a routerLink="/">Home</a>
                        <a href="https://www.sell.markpedia.com/" target="_blank">Sell On Markpedia</a>
                        <a routerLink="/logistic-service">Logistic Service</a>
                        <a routerLink="/tracking"><i class="fa fa-map-marker pulse" ></i>Tracking</a>
                        <a routerLink="/contact">Contact</a>
                        <!-- <a href="javascript:void(0)" (click)="goToBuyer()" *ngIf="user">My Profile</a> -->
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>














<div class="smallScreen ">
    <header  class="header-mobile" role="banner">

        <nav role="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>

                
            <ul id="menu">
                <div>
                    <div class="head">
                        <div class="logo-mob">
                            <a routerLink="/">
                                <img src="assets/images/new_logo.png" alt="Markpedia Logo">
                            </a>
                        </div>
                        
                    </div>

                    <div class="auth-link">
                            <a class="mob-auth-link" routerLink="/auth/login">Login</a>
                            <a class="mob-auth-link register" routerLink="/auth/register">Register</a>
                    </div>

                    <div class="mob-menu">
                        <a class="mob-menu-link" routerLink="/tracking"><i class="fa fa-map-marker pulse" ></i>Tracking</a>
                            <a class="mob-menu-link" routerLink="/logistic-service"><i class="fa-solid fa-truck-fast"></i>Logistic service</a>
                            <a class="mob-menu-link" href="https://www.sell.markpedia.com/"><i class="fa-solid fa-dollar-sign"></i>Sell on Markpedia</a>
                            <a class="mob-menu-link" routerLink="/contact"><i class="fa-solid fa-address-book"></i>Contact</a>
                    </div>

                    <div class="mob-category">
                        <div class="mob-category-title"><h5>Categories</h5></div>

                        <div class="mob-category-list">
                            <ul *ngIf="categoty_step === 1">
                                <li  *ngFor="let categorie of categories | slice:4:20">
                                    <a  routerLink="/products/category/{{categorie.id}}/{{categorie.name}}">{{categorie?.name}}</a>
                                    <div><i (click)="selectCategoryNext(categorie.children, categorie, 2)" class="fa-solid fa-angles-right"></i></div>
                                </li>
                            </ul>
                            <ul *ngIf="categoty_step === 2">
                                <div class="mob-category-list-head">
                                    <i class="fa-solid fa-angles-left" (click)="selectCategoryBack()"></i>
                                    <a  routerLink="/products/category/{{categoty_step_value.id}}/{{categoty_step_value.name}}">{{categoty_step_value.name}}</a>
                                </div>
                                <li  *ngFor="let categorie of categoty_step_values | slice:4:20">
                                    <a  routerLink="/products/category/{{categorie.id}}/{{categorie.name}}">{{categorie?.name}}</a>
                                    <div><i (click)="selectCategoryNext(categorie.children, categorie, 3)" class="fa-solid fa-angles-right"></i></div>
                                </li>
                            </ul>
                            <ul *ngIf="categoty_step === 3">
                                <div class="mob-category-list-head">
                                    <i class="fa-solid fa-angles-left" (click)="selectCategoryBack()"></i>
                                    <a  routerLink="/products/category/{{categoty_step_value.id}}/{{categoty_step_value.name}}">{{categoty_step_value.name}}</a>
                                </div>
                                <li  *ngFor="let categorie of categoty_step_values | slice:4:20">
                                    <a  routerLink="/products/category/{{categorie.id}}/{{categorie.name}}">{{categorie?.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
              
            </ul>
           </div>

           <div class="header-top-lang">
            <ul class="mobile-header-top-dropdown">
                <li class="mobile-header-top-dropdown-menu">
                    <a href="#" aria-haspopup="true">
                        <span>
                            <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" [alt]="translateService.currentLang" >  
                            <span *ngIf="translateService.currentLang == 'en'">
                                English
                            </span>
                            <span *ngIf="translateService.currentLang == 'fr'">
                                French
                            </span>
                            <span *ngIf="translateService.currentLang == 'chn'">
                                Chinese
                            </span> 
                        </span>
                        <i class="fa-solid fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown" aria-label="submenu">
                        <li *ngFor="let lang of translateService.getLangs()"> <a (click)="changeLang(lang)">
                            <img [src]="'assets/images/flags/'+lang+'.svg'" width="22" [alt]="lang" >  
                            <span *ngIf="lang == 'en'">
                                English
                            </span>
                            <span *ngIf="lang == 'fr'">
                                French
                            </span>
                            <span *ngIf="lang == 'chn'">
                                Chinese
                            </span>
                        </a></li>
                    </ul>
                </li >
                <li class="mobile-header-top-dropdown-menu" >
                    <a href="#" aria-haspopup="true">
                        <span>
                           {{currency?.currency}} 
                        </span>
                        <i class="fa-solid fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown" aria-label="submenu">
                        <li *ngFor="let currencie of currencies"> <a (click)="changeCurrency(currencie)" >{{currencie?.currency}} - {{currencie?.name}}</a></li>
                    </ul>
                </li >
            </ul>
            
        
        </div>
          </nav>

          <div class="header-middle">
            <div class="header-middle-box">
                <div class="header-middle-content">
                    <div class="header-middle-logo">
                        <a routerLink="/">
                            <img src="assets/images/new_logo.png" alt="Markpedia Logo">
                        </a>
                    </div>
                    
                    
                    <div class="header-middle-auth">
                        
                        <div class="header-middle-auth-cart btn-shopping-cart">
                            
                            <ul class="menu">
                                <li>
                                    <a routerLink="/cart" >
                                        <img src="assets/images/new/header/cart.svg" alt="Cart item images">
                                        <span  *ngIf="user">{{appService.Data.cartList.length}}</span>
                                    </a>
                                   
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mobile-header-bottom">
            <div class="mobile-header-middle-search">
                    
                <form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
                           
                    <input class="input-search-product-file" type="file" #filePicker (change)="searchProductsOnImage($event)">

                    <div class="mobile-header-middle-search-input">
                        <input type="text" value="" 
                            formControlName="product"
                            autocomplete="off" 
                            placeholder="{{ 'SEARCH_PRODUCT' | translate }}"
                            name="search"
                            [formControl]="control"
                            [(ngModel)]="search_product"
                        >
                        <select formControlName="categorie">
                            <option selected disabled value="All Categories">All Categories </option>
                            <option *ngFor="let categorie of categories" value="{{categorie?.name}}">{{categorie?.name}}</option>
                        </select>
                        <button type="submit">
                            <img src="assets/images/new/header/search.svg" alt="">
                        </button>
                    </div>

                    <div class="mobile-header-middle-camera">
                        <div class="camera-bg" (click)="filePicker.click()"><img src="assets/images/camera-5-64.png" width="20px" height="20px"  alt=""></div>
                    </div>
                    
                                    
                </form>
                
            </div>
        </div>
          
        
        <!-- <div class="header-top">
            <div class="header-top-box">
                <div class="header-top-left">
                    <div class="header-top-lang">
                        <ul class="header-top-dropdown">
                            <li class="header-top-dropdown-menu">
                                <a href="#" aria-haspopup="true">
                                    <span>
                                        <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" [alt]="translateService.currentLang" >  
                                        <span *ngIf="translateService.currentLang == 'en'">
                                            English
                                        </span>
                                        <span *ngIf="translateService.currentLang == 'fr'">
                                            French
                                        </span>
                                        <span *ngIf="translateService.currentLang == 'chn'">
                                            Chinese
                                        </span> 
                                    </span>
                                    <i class="fa-solid fa-chevron-down"></i>
                                </a>
                                <ul class="dropdown" aria-label="submenu">
                                    <li *ngFor="let lang of translateService.getLangs()"> <a (click)="changeLang(lang)">
                                        <img [src]="'assets/images/flags/'+lang+'.svg'" width="22" [alt]="lang" >  
                                        <span *ngIf="lang == 'en'">
                                            English
                                        </span>
                                        <span *ngIf="lang == 'fr'">
                                            French
                                        </span>
                                        <span *ngIf="lang == 'chn'">
                                            Chinese
                                        </span>
                                    </a></li>
                                </ul>
                            </li >
                            <li class="header-top-dropdown-menu" >
                                <a href="#" aria-haspopup="true">
                                    <span>
                                       {{currency?.currency}} 
                                    </span>
                                    <i class="fa-solid fa-chevron-down"></i>
                                </a>
                                <ul class="dropdown" aria-label="submenu">
                                    <li *ngFor="let currencie of currencies"> <a (click)="changeCurrency(currencie)" >{{currencie?.currency}} - {{currencie?.name}}</a></li>
                                </ul>
                            </li >
                        </ul>
                        
                      
                    </div>
                   
                </div>
                <div class="header-top-right">
                  
                    
                </div>
            </div>
            
        </div> -->
     <!-- <div class="row">
         <nav class="small-screen-nav" role="navigation">
          
             <div id="menuToggle">
               <input type="checkbox" />
                 <span></span>
                 <span></span>
                 <span></span>
    
             <div id="menu">
                 <div class="hide-menu-header">
                     <div class="top">
                         <a routerLink="/"><img src="assets/images/new_logo.png"  title="Your MarketPlace" alt="Your MarketPlace" /></a>
                         <div class="hide-cart">
                             <div class="shopcart">
                                 <a routerLink="/cart">
                                    <img src="assets/image/theme/icons/home2/cart.gif" alt="">
                                 </a>
                                 <div class="cart-info-value">
                                    {{appService.Data.cartList.length}}
                                </div>
                             </div>
                         </div>
                     </div>
                     <div class="hide-menu-register">
                         <button mat-raised-button  routerLink="/auth/login"  color="primary" type="button">Login</button>
                         <button mat-raised-button  routerLink="/auth/register"  color="primary" type="button">Register</button>
                     </div>
                     <div class="hide-menu-menu">
                         <div>
                             <a href="/checkout">Checkout</a>
                         </div>
                         <div class="line">
                             <a href="/tracking">Track Orders</a>
                         </div>
                         <div>
                             <a target="_blank" href="https://www.sell.markpedia.com/">Sell On Markpedia</a>
                         </div>
                         <div class="line">
                             <a href="/contact">Contact</a>
                         </div>
                        
                     </div>
    
                     <div class="hide-menu-categories">
                         <h4 class="text-red">Categories</h4>
                         <hr>
                         <div *ngIf="categoty_step === 1">
                             <div  *ngFor="let cate of categoty_step_values">
                                 <div class="category-item" (click)="selectCategoryNext(cate.children, cate, 2)">
                                     <p>
                                         {{cate.name}}
                                     </p>
                                     <i class="fa-solid fa-chevron-right"></i>
                                 </div>
                                 <hr>
                             </div>
                         </div>
                         <div *ngIf="categoty_step != 1">
                             <div class="category-item-selected" (click)="selectCategoryBack()">
                                 <i class="fa-solid fa-chevron-left"></i>
                                 <p>{{categoty_step_value?.name}}</p>
                             </div>
                             <hr>
                             <div  *ngFor="let cate of categoty_step_values ">
                                 <div class="category-item" *ngIf="cate.children.length > 0"  (click)="selectCategoryNext(cate.children, cate, 3)">
                                     <p>{{cate.name}}</p>
                                     <i class="fa-solid fa-chevron-right"></i>
                                 </div>
                                 <a class="category-item" *ngIf="cate.children.length == 0" routerLink="/products/category/{{cate.id}}/{{cate.name}}" >
                                     <p>{{cate.name}}</p>
                                     <i class="fa-solid fa-chevron-right"></i>
                                 </a>
                                 <hr>
                             </div>
                         </div>
                     </div>
                 </div>
               
                
             </div>
             
            </div>
    
            <div class="small-screen-logo">
             <a routerLink="/"><img src="assets/images/new_logo.png"  title="Your MarketPlace" alt="Your MarketPlace" /></a>
             </div>

             <div class="cart-info" >
                <div class="cart-info-icon">
                   <a routerLink="/cart">
                    <img src="assets/image/theme/icons/home2/cart.gif" alt="">
                   </a>
                </div>
                <div class="cart-info-value">
                    {{appService.Data.cartList.length}}
                </div>
            </div>
    
            
            </nav>
     </div> -->
    
     <!-- <div class="small-screen-search">
         <div class="search-header-w">
             <div id="sosearchpro" class="sosearchpro-wrapper so-search ">
                 <form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
                     
                     <input class="input-search-product-file" type="file" #filePicker (change)="searchProductsOnImage($event)">
                     <div id="search0" class="search input-group form-group">
                        <div style="position:relative;display:flex;"  style="border-top:2px solid #A10710;border-bottom:2px solid #A10710;border-left:2px solid #A10710; border-top-left-radius: 25px;border-bottom-left-radius: 25px; width: 100%;">
                                       
                            <input type="text"
                           
                             value="" 
                             formControlName="product"
                              autocomplete="off" 
                              placeholder="{{ 'SEARCH_PRODUCT' | translate }}"
                               name="search"
                             [formControl]="control"
                             [(ngModel)]="search_product"
                             (keyup)="filterResults()"
                             >
                         
                             
                         </div>
                         
                          
                         <div class="input-group-btn" style="height: 30px;top:1px;">
                         <button type="submit" class="button-search m_seach"  style="border-top:2px solid #A10710; height: 44px; min-width: 128px; border-bottom:2px solid #A10710; "   name="submit_search">{{'SEARCH_' | translate}}</button>
                         </div>
                     </div>
                     <input type="hidden" name="route" value="products/search" />
                 </form>
             </div>
         </div>
     </div> -->
    </header>
    
<!-- <nav class="bottom-nav">
    <div class="bottom-nav-item item-home active" (click)="activeLink('item-home')">
      <div class="bottom-nav-link" routerLink="/" >
        <i class="fa-solid fa-house"></i>
        <span>Home</span>
      </div>
    </div>
    <div class="bottom-nav-item item-cart" (click)="activeLink('item-cart')">
      <div class="bottom-nav-link" routerLink="/cart">
        <i  class="fa-solid fa-cart-shopping"></i>
        <span>Cart</span>
      </div>
    </div>
    <div class="bottom-nav-item item-checkout" (click)="activeLink('item-checkout')">
        <div class="bottom-nav-link" routerLink="/checkout">
            <i class="fa-solid fa-bag-shopping"></i>
          <span>Checkout</span>
        </div>
      </div>
    <div class="bottom-nav-item item-track" (click)="activeLink('item-track')">
      <div class="bottom-nav-link" routerLink="/tracking">
        <i class="fa-solid fa-location-dot"></i>
        <span>Tracking</span>
      </div>
    </div>
    

    
  </nav>  -->

 
</div>
