<div *ngIf="products" >
    <div class="slide-mobile"   style="margin-top: 30px;">
        <div class="" *ngFor="let product of products ">
          <a  routerLink="/products/{{product?.my_product_flat?.id}}/{{removeSlash(product?.my_product_flat?.name)}}" target="_self">
          <div class="">
            <img src="{{product.images?.path}}" alt="">   
            <div>
                {{ truncate(product?.my_product_flat?.name, 25)}}
            </div>
            <div class="card-footer">
                <div class="wcf-left">
                    <app-rating [rating]="3.5"></app-rating>
                </div>
                <div class="wcf-right">
                    {{tempsEcoule(product?.created_at)}}
                  </div>
            </div>
            
            <div class="price" *ngIf="product?.price">
                {{currency?.symbol}} {{product?.price* (currency?.value || 1)  | number : '1.1-2' }}
            </div>
          </div>
          </a>
        </div>
        
    </div>
</div>