import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID, NO_ERRORS_SCHEMA } from '@angular/core'; 
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { isPlatformBrowser, NgFor, NgIf, NgClass, SlicePipe, DecimalPipe } from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { v4 as uuid } from 'uuid';
import { map, Observable, startWith } from 'rxjs';
import { Category, cartitem } from 'src/app/app.models';
import { AppSettings, Settings } from 'src/app/app.settings';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/auth.service';
import { SidenavMenuService } from 'src/app/theme/components/sidenav-menu/sidenav-menu.service';
import { TrackingComponent } from 'src/app/pages/tracking/tracking.component';
import { SearchComponent } from 'src/app/pages/search-image/search.component';
import { MatButtonModule } from '@angular/material/button';
import { LangComponent } from '../lang/lang.component';
import { QRCodeModule } from 'angularx-qrcode';
interface Currency {
  base: boolean;
  currency: string;
  name: string;
  symbol: string;
  value?: number;
}
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
    selector: 'app-header-2',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    schemas: [NO_ERRORS_SCHEMA],
    imports: [RouterLink, FormsModule, NgFor, LangComponent, ReactiveFormsModule, NgIf, NgClass, MatButtonModule, SlicePipe, DecimalPipe, TranslateModule, QRCodeModule]
})
export class HeaderComponent implements OnInit {

  animal!: string;
  name!: string;
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  public currencies: Currency[] = [
    {
      currency: 'USD',
      name: 'Dollar américain',
      symbol: '$',
      base: true,
      value: 1
    }
  ] ;
  public products: any[]=[];
  is_not_authenticate=true
  is_google_authenticated = false;
  filteredCodes!: any;
  filteredItems!: any;
  google_avatar!: any;
  public showBackToTop:boolean = false; 
  public isMenuOpen:boolean = false; 
  public categories: Category[] = []; 
  public categories_mobile: Category[] = []; 
  public category!:Category;
  public sidenavMenuItems!:Array<any>;
  public currentHref: string = "";
  @ViewChild('sidenav', { static: true }) sidenav:any;
  user: any = JSON.parse(localStorage.getItem("markpediausersessionid")!);
  userProfile: any = JSON.parse(localStorage.getItem("markpediausersessionid")!);
  cart_products!: any;
  nu=0;
  public settings: Settings;
  total = [];
  grandTotal = 0;
  cartItemCount = [];
  cartItemCountTotal = 0;
  searchForm!: FormGroup;
  search_product="";
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets!: Observable<string[]>;

  // pathname: any;
  control = new FormControl();
  imageProduct: any;
  categoty_step: number = 1;
  categoty_step_values: Category[]=[]
  categoty_step_value!: Category;
  categoty_step_values_last: Category[]=[];
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')!); 
  categoty_step_value_last!: Category;
  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              public authService: AuthenticationService,
              public sidenavMenuService:SidenavMenuService,
              public router:Router,
              public dialog: MatDialog,
              public formBuilder: FormBuilder,
              public translateService: TranslateService,
              location: Location,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) { 
              this.loadStyle('../../assets/template/css/bootstrap/css/bootstrap.min.css');
    this.settings = this.appSettings.settings; 
    if (this.language != null) {
      this.translateService.use(this.language);   
    }
  //  this.pathname = location.path();
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.currentHref = location.path();
      } else {
        this.currentHref = 'Home'
      }
    });
    const origin = window.location.host;
    
    this.getCurrencyDevice()
  }

  public changeLang(lang:string){ 
    this.translateService.use(lang);   
    localStorage.setItem('MarkpediaLanguage', JSON.stringify(lang))

  } 

  public removeSlash(name: string){
    return name.split(' ').join("-")
    
  }


  private getCurrencyDevice(){
    this.authService.getsCurrencyDevice("EUR,GBP,NGN,XOF,XAF,CNY,CAD,ZAR,DZD,AOA,EGP,GHS,KES,MAD,TND").then(
      (res:any)=>{
        const CAD = {
          currency: 'CAD',
          name: 'Dollar canadien',
          symbol: 'CAD',
          base: false,
          value: res?.rates?.CAD
        }
        this.currencies.push(CAD)

        
        const EUR = {
          currency: 'EUR',
          name: 'Euro',
          symbol: '€',
          base: false,
          value: res?.rates?.EUR
        }
        this.currencies.push(EUR) 

        
        const GBP = {
          currency: 'GBP',
          name: 'Livre sterling',
          symbol: '£',
          base: false,
          value: res?.rates?.GBP
        }
        this.currencies.push(GBP)

        const CNY = {
          currency: 'CNY',
          name: 'Yuan chinois',
          symbol: '¥',
          base: false,
          value: res?.rates?.CNY
        }
        this.currencies.push(CNY)

        const XAF = {
          currency: 'XAF',
          name: 'Franc CFA',
          symbol: 'XAF',
          base: false,
          value: res?.rates?.XAF
        }
        this.currencies.push(XAF)

        const XOF = {
          currency: 'XOF',
          name: 'Franc CFA',
          symbol: 'XOF',
          base: false,
          value: res?.rates?.XOF
        }
        this.currencies.push(XOF)

        const NGN = {
          currency: 'NGN',
          name: 'Naira',
          symbol: 'NGN',
          base: false,
          value: res?.rates?.NGN
        }
        this.currencies.push(NGN)

        const ZAR = {
          currency: 'ZAR',
          name: 'Rand',
          symbol: 'ZAR',
          base: false,
          value: res?.rates?.ZAR
        }
        this.currencies.push(ZAR)

        const DZD = {
          currency: 'DZD',
          name: 'Dinar algérien',
          symbol: 'DZD',
          base: false,
          value: res?.rates?.DZD
        }
        this.currencies.push(DZD)

        const AOA = {
          currency: 'AOA',
          name: 'Kwanza',
          symbol: 'AOA',
          base: false,
          value: res?.rates?.AOA
        }
        this.currencies.push(AOA)

        const EGP = {
          currency: 'EGP',
          name: 'Livre égyptienne',
          symbol: 'EGP',
          base: false,
          value: res?.rates?.EGP
        }
        this.currencies.push(EGP)

        const GHS = {
          currency: 'GHS',
          name: 'Cedi',
          symbol: 'GHS',
          base: false,
          value: res?.rates?.GHS
        }
        this.currencies.push(GHS)

        const KES = {
          currency: 'KES',
          name: 'Shilling kényan',
          symbol: 'KES',
          base: false,
          value: res?.rates?.KES
        }
        this.currencies.push(KES)

        const MAD = {
          currency: 'MAD',
          name: 'Dirham marocain',
          symbol: 'MAD',
          base: false,
          value: res?.rates?.MAD
        }
        this.currencies.push(MAD)

        const TND = {
          currency: 'TND',
          name: 'Dinar tunisien',
          symbol: 'TND',
          base: false,
          value: res?.rates?.TND
        }
        this.currencies.push(TND)

       
        this.authService?.currencies$.next(this.currencies)
        
        
      }).catch((err)=>{
      //console(error)
    })
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
  
    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;
  
      head.appendChild(style);
    }
  }
  public getCategories(){  
    
    this.authService.getsEntite("api/get_parent_child_categorie").then(
      (res:any)=>{
        this.authService?.categories$.next(res)
        this.categories = res;
        this.categoty_step_values = res;
        //console("this.categories get parent", this.categories)
      }).catch((err)=>{
      //console(error)
    })

    // this.appService.getMCategories("categories").subscribe(data => {
    //   //console("data : ", data)
    //   this.categories = data; 
    //   this.categories.shift();
    // }); 
  }
  activeLink(select_item: string){
    let navItems = document.querySelectorAll(".bottom-nav-item");

    navItems.forEach(function(e, i) {
      e.addEventListener("click", function(e) {
        navItems.forEach(function(e2, i2) {
          e2.classList.remove("active");
        });
        
      });
    });
    setTimeout(()=> {
      let item = document.querySelector("."+select_item);
      item!.classList.add("active");
    }, 1000)
  }
  
  selectCategoryNext(child: Category[], category: Category, step: number){
    this.categoty_step_values_last = this.categoty_step_values;
    this.categoty_step_value_last = this.categoty_step_value;
    this.categoty_step_values = child;
    this.categoty_step_value = category;
    this.categoty_step = step;

    
  }


  selectCategoryBack(){
    
    if (this.categoty_step === 2) {
      this.categoty_step = 1;
      this.categoty_step_values = this.categories;
      
    }
    if (this.categoty_step === 3) {
      this.categoty_step = 2;
      this.categoty_step_values = this.categoty_step_values_last;
      this.categoty_step_value = this.categoty_step_value_last;
      
    }

    
  }

  setDynamicStyle(cssURL: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = `${cssURL}`;
    head.appendChild(style);
}

  openDialog(): void {
    const dialogRef = this.dialog.open(TrackingComponent, {
      width: '50rem',
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      //console('The dialog was closed');
      this.animal = result;
    });
  }
  openDialogue(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '50rem',
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      //console('The dialog was closed');
      this.animal = result;
    });
  }
  submitSearch(){
    let value = this.searchForm.value;
    
    this.router.navigate(['/products/search/'+value.product+'/'+value.categorie])
  }
  private _filter(value: string, source:any): string[] {
    const filterValue = this._normalizeValue(value);
    return source.filter((product: any) => this._normalizeValue(product).includes(filterValue));
  }

  updateAutocomplete(){
    let newCodes: string[]  = [];

    setTimeout(() => {
      this.products.forEach(e => {
        newCodes.push(e.my_product_flat.name.toString());
      });
      newCodes = newCodes.filter(this.onlyUnique);  
      this.updateCodes(newCodes);
      
    }, 1000);
  }

  onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  updateCodes(source: any){
    this.filteredCodes = this.control.
    valueChanges.pipe(startWith(''), map(value => this._filter(value, source)));
      }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  ngOnInit() {
    //  this.filteredStreets = this.control.valueChanges.pipe(
    //    startWith(''),
    //    map(value => this._filter(value)),
    //  );
    

    this.searchForm = this.formBuilder.group({
        product:['', Validators.required],
        categorie:['All Categories',Validators.required]
    })

    this.cart_products=this.appService.Data.cartList;
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    
    // this.user = this.userProfile;
    
    
    // if (this.pathname == "/" || this.pathname == "") {
    //   const element = document.querySelector('.vertical-wrapper')
    //   element!.classList.remove('active')
    // }else{
    //   const element = document.querySelector('.vertical-wrapper')
    //   element!.classList.add('active')
    // }
   
  } 
 
     
  public changeCurrency(currency: any){
    if (currency?.currency === 'USD') {
      this.currency = currency;
      localStorage.setItem('MarkpediaDevise', JSON.stringify(currency))
      this.authService.currency$.next(currency)
    }else{
      this.authService.getsCurrencyDevice(currency?.currency).then(
        (res:any)=>{
          this.currency = currency;
          this.currency.value = Object.values(res?.rates)[0];
          localStorage.setItem('MarkpediaDevise', JSON.stringify(this.currency))
          this.authService.currency$.next(this.currency)
          
        }).catch((err)=>{
        //console(error)
      })
    }
    
    
  } 

  
  logout(){

    
    localStorage.removeItem("markpediausersessionid");
    if(localStorage.getItem("google_avatar")){
      localStorage.removeItem("google_avatar");
    }
    this.user = null;
    
	  this.router.navigate(['/']);
    window.location.reload()
  }

  public getLangText(lang: any){
    if(lang == 'de'){
      return 'German';
    }
    else if(lang == 'fr'){
      return 'French';
    }
    else if(lang == 'ru'){
      return 'Russian';
    }
    else if(lang == 'tr'){
      return 'Turkish';
    }
    else{
      return 'English';
    }
  } 
  public remove(product: cartitem) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.grandTotal = this.grandTotal - this.total[product.id]; 
      this.appService.Data.totalPrice = this.grandTotal;       
      this.total.forEach(val => {
        if(val == this.total[product.id]){
          // this.total[product.id] = 0;
        }
      });

      localStorage.setItem('cartList',JSON.stringify(this.appService.Data.cartList))
      this.cartItemCountTotal = this.cartItemCountTotal - this.cartItemCount[product.id]; 
      this.appService.Data.totalCartCount = this.cartItemCountTotal;
      this.cartItemCount.forEach(val=>{
        if(val == this.cartItemCount[product.id]){
          // this.cartItemCount[product.id] = 0;
        }
      });
      this.appService.resetProductCartCount(product);
      let data = product;
      data.status = "Rejected"
      this.authService.upDateUser("api/cartitems/"+data?.id+"/", data).then((response)=>{
        
       
        }).catch((error)=>{
          
        })

      if (this.appService.Data.cartList.length == 0) {
        this.appService.Data.cartList.length = 0;
        this.appService.Data.totalPrice = 0;
        this.appService.Data.totalCartCount = 0;
        localStorage.removeItem('cartList')
        localStorage.removeItem('totalCartCount')
        localStorage.removeItem('totalPrice')
        localStorage.removeItem('cart')
      }
    }     
  }

  filterResults() {
    if (!this.searchForm.value.product) {
      this.filteredItems = [...this.products];
    } else {
      this.filteredItems = [];
      this.filteredItems = this.products.filter((product) => {
        return product.my_product_flat.name.toUpperCase().indexOf(this.searchForm.value.product.toUpperCase()) > -1;
      });
    }
 }


  public changeCategory(event: any){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }
  public updateCart(value: any){
    if(value){
      // this.total[value.productId] = value.total;
      // this.cartItemCount[value.productId] = value.soldQuantity;
      this.grandTotal = 0;
      this.total.forEach(price=>{
        this.grandTotal += price;
      });
      this.cartItemCountTotal = 0;
      this.cartItemCount.forEach(count=>{
        this.cartItemCountTotal +=count;
      });
     
      this.appService.Data.totalPrice = this.grandTotal;
      this.appService.Data.totalCartCount = this.cartItemCountTotal;

      this.appService.Data.cartList.forEach(product=>{
        this.cartItemCount.forEach((count,index)=>{
          if(product.id == index){
            product.cartCount = count;
          }
        });
      });
      
    }
  }

  public clear(){
    this.appService.Data.cartList.forEach(product=>{
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }
 

  public changeTheme(theme: any){
    this.settings.theme = theme;       
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){}

 
  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { 
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }  
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);   
    let header_toolbar = document.getElementById('header-toolbar'); 
    if(header_toolbar){ 
      if(scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else{
        if(!document.documentElement.classList.contains('cdk-global-scrollblock')){
          this.settings.mainToolbarFixed = false;
        }        
      } 
    } 
    else{
      this.settings.mainToolbarFixed = true;
    }  
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        // this.sidenav.close(); 
      }                
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }
  menu = [

    '/sell-on-markpedia',
    '/',
    '/logistic-service',
    '/contact',
    '/tracking',
  ];


  searchProductsOnImage(event: Event){

    if ((event.target as HTMLInputElement).files!.length > 0) {
      const file = (event.target as HTMLInputElement).files![0];
      const formData: FormData = new FormData();
      formData.append("image", file);
      this.router.navigate(['/products/search',formData])
    }
    
  }

  goToLogin(){
    const userProfile = JSON.parse(localStorage.getItem("markpediausersessionid")!); 
    if (userProfile == null) {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: "dispute" } })
    }else{
      this.router.navigate(['/dispute'])
    }
    
  }
  
  
public goToVendor() {
  const user = JSON.parse(localStorage.getItem("markpediausersessionid")!); 
  // const origin = window.location.origin;
  window.open("https://vendor.markpedia.com/"+user?.user?.customer_id+"/"+user?.user?.seller_id+"/"+uuid(), '_blank');
}

public goToBuyer() {
  const user = JSON.parse(localStorage.getItem("markpediausersessionid")!); 
  // const origin = window.location.origin;
  if (user?.user?.is_seller == "False") {
    window.open("https://buyer.markpedia.com/"+user?.user?.customer_id+"/"+uuid(), '_blank');
  }else{
    window.open("https://vendor.markpedia.com/"+user?.user?.customer_id+"/"+user?.user?.seller_id+"/"+uuid(), '_blank');
  }
  
}

toggleMobileNav(){
  const nav = document.querySelector('#nav');
  const menu = document.querySelector('#menu') as HTMLUListElement;
  const menuToggle = document.querySelector('.nav__toggle');
  this.isMenuOpen = !this.isMenuOpen;

  menuToggle!.setAttribute('aria-expanded', String(this.isMenuOpen));
  menu!.hidden = !this.isMenuOpen;
  nav!.classList.toggle('nav--open');

}


}
