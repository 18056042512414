<div *ngFor="let category of categories">
    <ul>
        <li mat-menu-item (click)="changeCategory($event)" class="cat">
           <i class="fa fa-circle-o"></i> &nbsp;&nbsp; <span>{{category.name}}</span>
        </li>
        <hr>
    </ul>
     <!-- <div *ngIf="category.hasSubCategory" class="sub-category">
        <app-category-list [categories]="categories" [categoryParentId]="category.id" (change)="changeCategory($event)"></app-category-list>
    </div> -->
</div>