<div *ngIf="products">
  <owl-carousel-o class="custom-carousel" [options]="customOptions">

    <ng-container *ngFor="let prod of products">
      <ng-template carouselSlide [id]="prod.id" >
        <div class="wsk-cp-product" style="margin-right: 20px;">
          <div class="product-img">
            <div class="product-img-box">
              <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images.path" alt="Product"   width="100%"   /></a>
            </div>
            
        </div>
            <!-- <div class="wsk-cp-img">
              <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images.path" alt="Product"   width="100%"   /></a>
            </div> -->
            <div class="wsk-cp-text">
            
              <div class="title-product">
                <a style="color: black; text-decoration: none;"  routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                  <h4>{{ truncate(prod?.my_product_flat?.name, 25)}}</h4>
                </a>
                
              </div>
              
              <div class="card-footer">
                <div class="wcf-left">
                  <app-rating [rating]="3.5"></app-rating>
                </div>

                  <div class="wcf-right">
                    {{tempsEcoule(prod?.created_at)}}
                  </div>
            </div>
         
              <div class="card-footer product-footer">
                <div class="wcf-left"><span style="color: #A10710; text-decoration: none;"  class="price">{{currency?.symbol}} {{ (prod?.price || 0) * (currency?.value || 1) | number: '1.1-2' }}</span></div>
                <div class="wcf-right"><a style="cursor: pointer;" (click)="addToCart(prod)" class="buy-btn"><i class="fa fa-shopping-cart"></i></a></div>
              </div>
            </div>
          </div>
      </ng-template>
    </ng-container>
  
  </owl-carousel-o>
</div>


 
<!-- <div *ngIf="products" class="swiper-container" >
    <div class="swiper-wrapper h-100">      
        <div *ngFor="let prod of products" class="swiper-slide">
          <div class="wsk-cp-product">
            <div class="wsk-cp-img">
              <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images[0].path" alt="Product"  width="auto" height="180px"  /></a>
            </div>
            <div class="wsk-cp-text">
            
              <div class="title-product">
                <a style="color: black; text-decoration: none;"  routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                  <h4>{{ truncate(prod?.my_product_flat?.name, 20)}}</h4>
                </a>
                
              </div>
              
              <div class="card-footer">
                <div class="wcf-left">
                    <ng-container>
                        <div class="rating">
                          <div [class.dense]="dense">
                            <ngx-material-rating
                              [color]="color"
                              [disabled]="disabled"
                              [dense]="dense"
                              [readonly]="readonly"
                              [(ngModel)]="value"
                              [max]="max"
                            >
                            </ngx-material-rating>
                          </div>
                        </div>
                      </ng-container>
                </div>

                  <div class="wcf-right">
                    {{tempsEcoule(prod?.created_at)}}
                  </div>
            </div>
         
              <div class="card-footer product-footer">
                <div class="wcf-left"><span style="color: #A10710; text-decoration: none;"  class="price">{{currency?.symbol}} {{ (prod?.price || 0) * (currency?.value || 1) | number: '1.1-2' }}</span></div>
                <div class="wcf-right"><a style="cursor: pointer;" (click)="addToCart(prod)" class="buy-btn"><i class="fa fa-shopping-cart"></i></a></div>
              </div>
            </div>
          </div>
            
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
</div>  -->