import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgIf, NgFor, SlicePipe, DatePipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/auth.service';
import { ThemePalette } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { config } from 'src/config/local';

@Component({
    selector: 'app-blog-list',
    templateUrl: './blog-list.component.html',
    styleUrls: ['./blog-list.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, SlicePipe, DatePipe, TranslateModule],
})
export class BlogListComponent implements OnInit {

  @Input('articles') articles: Array<any> = [];
  public config: SwiperConfigInterface = {};
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  public settings: Settings;
  public value: number = 4;
  public max: number = 5;
  public disabled: boolean = false;
  public dense: boolean = true;
  public readonly: boolean = true;
  public color: ThemePalette = 'warn';
  base_url: string = config.cms_api_image
  constructor(public authService: AuthenticationService,public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router) { 
    this.settings = this.appSettings.settings;
    this.authService?.currency$.subscribe((currency: any) =>{
      this.currency=currency
   })
   console.log("articles", this.articles)
  }

  ngOnInit() { }
  
  ngAfterViewInit(){

  }

  

  
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')!); 
 public tempsEcoule = (date: string) => {
  let temps = "58 min";
  const currentDate = new Date();
  const current_year = currentDate.getFullYear();
  const current_month = currentDate.getMonth() + 1;

  const oldDate = new Date(date);
  const old_year = oldDate.getFullYear();
  const old_month = oldDate.getMonth() + 1;

  if (this.language != null) {
    if (current_year >= old_year) {
      if (this.language == "chn") {
        temps = (current_year - old_year + 1).toString() + " 年";
        return temps;
      } else if (this.language == "fr") {
        temps = (current_year - old_year + 1).toString() + " an(s)";
        return temps;
      } else {
        temps = (current_year - old_year + 1).toString() + " year(s)";
        return temps;
      }
    }
  } else {
    if (current_year >= old_year) {
      temps = (current_year - old_year + 1).toString() + " year";
      return temps;
    }
  }

  // Add a default return statement here
  return temps; // You can return the default value or handle it differently based on your requirements.
};


  public truncate(str: any, maxlength: any) {
    return (str.length > maxlength) ?
      str.slice(0, maxlength - 1) + '…' : str;
  }
  
  public removeSlash(name: string){
    return name.split(' ').join("-")
    
  }


}