import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core'; 
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppSettings, Settings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/auth.service';
import { NgIf } from '@angular/common';

export interface DialogData {
    animal: string;
    name: string;
  }

@Component({
    selector: 'app-tracking',
    templateUrl: 'tracking.component.html',
    styleUrls: ['./tracking.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, NgIf, RouterLink]
})
  export class TrackingComponent  {

    trackinform: FormGroup;
    trackinformSubmitted: boolean=false;
    settings:Settings;
    orders_!: any;
    errorMessage!: any;
    steps!: any;
    tracking!: any;

    constructor(
      private router: Router,
      private fb: FormBuilder,
      public appSettings:AppSettings,
     public dialog: MatDialog, 
     public authService : AuthenticationService,
    ) {
      this.settings = this.appSettings.settings;
      this.trackinform = this.fb.group({
        number: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(52)]),
      });
    }
  
    onNoClick(): void {
    } 

    gotoTrack(){
      const user = JSON.parse(localStorage.getItem("markpediausersessionid")!); 
      if (user) {
        this.router.navigate(['/tracking', this.trackinform.get('number')?.value])
      }else{
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: "tracking", number:  this.trackinform.get('number')?.value} })
      }

        
    }
    

    getDetails(number: any){
      let num = {
        "number":number
      }
      this.authService.createNewUser('api/customer-track-order/',num). 
          then((res)=>{
            this.orders_ = res
            //console("details",res)

            this.getOrders(1);
            this.getsteps(1)

            const dialogRef = this.dialog.open(TrackingComponent, {
              data: {
                data: this.orders_,
                steps: this.steps,
                tracking_number:this.trackinform.get('number')?.value
              },
              panelClass: ['theme-dialog'],
              autoFocus: false,
              direction: (this.settings.rtl) ? 'rtl' : 'ltr'
            });
            //console(res);
          }).catch((error)=>{
              this.errorMessage = "An error has occured !" 
              alert("Wrong tracking number !")
          })
  
    }
    onSubmit(){
      this.trackinformSubmitted = true;
    
      this.getDetails(this.trackinform.get('number')?.value);
      
   
    }
    
    // onNoClick(): void {
    //   this.dialogRef.close();
    // }

    // gotoTrack(){
    //   this.dialogRef.close();
    //     this.router.navigate(['/tracking'])
    // }

    getOrders(id: any){
      this.authService.getUserById('api/get-seller-tracking-orders/',id). 
          then((res)=>{
            this.tracking = res
            //console(res);
          }).catch((error)=>{
              this.errorMessage = "An error has occured !"
          })
  
    }
  
    public getsteps(id: any){  
     
      this.authService.getsEntite("api/seller-steps/"+id).then(
        (res:any)=>{
          this.steps = res
          //console(res)
        }).catch((err)=>{
      //console(error)
    })
  
      
      // this.appService.getMsteps("steps").subscribe(data => {
      //   //console("data : ", data)
      //   this.steps = data; 
      //   this.steps.shift();
      // }); 
    }
}