
import { ApplicationConfig } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';


import { routes } from './app.routes';

import { enableProdMode, importProvidersFrom } from '@angular/core';
export function HttpLoaderFactory(httpClient: HttpClient) { 
    return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json');
  }
  
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderConfig, PB_DIRECTION, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { OwlModule } from 'ngx-owl-carousel';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withHashLocation, withPreloading, provideRouter, Routes, PreloadAllModules } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { AppService } from './app.service';
import { AppSettings } from './app.settings';
import { MarkdownModule } from 'ngx-markdown';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsSize: 60,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  bgsOpacity: 0.5,
  bgsPosition: "center-center",
  bgsType: "ball-spin-clockwise",
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "red",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "ball-spin-clockwise",
  gap: 24,
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  hasProgressBar: true,
  text:"markpedia loading ..." ,
  textColor: "#f8272d",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300
  
};


export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // OwlModule, 
    //NgxSpinnerModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    // }),
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }), 
    
    SharedModule, 
    CommonModule,
     MatCommonModule, 
     NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    //StripeModule.forRoot("pk_test_51JVKNBLWVGsqrNVIFHOeElmhArjF4eLZV9jsvjvLpYIe5O0DhoDsNJTz5pDR0nSzfLpgyOZKuvCrg3tPsHHsyWJ700ZkGDaYfo"),
    // NgxStripeModule.forRoot('pk_test_51KWDgODXUpTPBoS9cS25BkBWPEIVPdPhepSNFumJeKtYnLycXJV6HZgGYhfDNqY72o9sOgYBkRK3SgByAqLSTYSB00qQ0KY5m4'),
    NgxUiLoaderHttpModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatDialogModule, 
    Ng2SearchPipeModule),
    AppSettings,
    AppService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideClientHydration()
]
};
