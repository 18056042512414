import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { AuthenticationService } from 'src/app/auth.service';
import { FilterByIdPipe } from '../../../theme/pipes/filter-by-id.pipe';
import { ControlsComponent } from '../../controls/controls.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RatingComponent } from '../../rating/rating.component';
import { NgFor, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrls: ['./product-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatDialogModule, NgFor, RatingComponent, MatTooltipModule, ControlsComponent, DecimalPipe, FilterByIdPipe]
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  constructor(public appService:AppService, 
    public authService: AuthenticationService,public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public product: any) { 
                this.authService?.currency$.subscribe((currency: any) =>{
                  this.currency=currency
               })
              }

  ngOnInit() { }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}