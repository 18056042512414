<div class="tracking">

    <div class="tracking-header">
      <div class="tracking-header-title">
        <h1>Tracking Services Process</h1>
      </div>
    </div>
  
    <div class="tracking-sub-header">
        <h2>Track my Goods</h2>
        <p>Need the status of your shipment or proof of delivery? Enter your tracking number or reference number below.</p>
    </div>
  
    <div class="tracking-number-card">
      <p>
        Enter up to 30 of your MARKPEDIA tracking, door tag, or MARKPEDIA Office order numbers (one per line).
      </p>
  
      <form [formGroup]="trackinform"  (ngSubmit)="onSubmit()">
        <div class="tracking-form">
          <input type="text" formControlName="number" name="number" placeholder="Enter Tracking Number EX: TRACK-12555" required >
          <img src="assets/images/plus.svg" alt="">
        </div>
      </form>
  
      <div class="help">
        <button class="tack-now" (click)="gotoTrack()">Track now</button>
        <!-- <span>
          <img src="assets/images/help.svg" alt="">
          Need help?
        </span> -->
      </div>
    </div>
  
    <!-- <button class="tack-now" (click)="gotoTrack()">Track now</button> -->
  
    <div class="process">
      <!-- <h3>The Process</h3> -->     
      <img src="assets/images/process.svg" alt="">
      <img src="assets/images/process-image.svg" alt="">
  
    </div>
  
  
    <div class="carte">
      <img src="assets/images/track-image1.svg" alt="">
    </div>
  
    <div class="banner">
      <img src="assets/images/track-image.svg" alt="">
    </div>
  </div>