
<ngx-ui-loader></ngx-ui-loader>
 <div id="app" class=" app" 
                [ngClass]="settings.theme" 
                [class.main-toolbar-fixed]="settings.mainToolbarFixed"
                [dir]="(settings.rtl) ? 'rtl' : 'ltr'" style="width: 100%;"> 
                
    <router-outlet></router-outlet> 
   <!-- <ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>-->     
</div> 

