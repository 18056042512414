import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-main-carousel',
    templateUrl: './main-carousel.component.html',
    styleUrls: ['./main-carousel.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatButtonModule, MatIconModule, NgbCarouselModule],
    providers: [NgbCarouselConfig] 
})
export class MainCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];
  @Input('loading_slides') loading_slides: Boolean = true;
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  // public config: SwiperConfigInterface = {};
  // private pagination: SwiperPaginationInterface = {
  //   el: '.swiper-pagination',
  //   clickable: true
  // };
  constructor(config: NgbCarouselConfig) {
   
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    
   }

  ngOnInit() { 
   
  }

  ngAfterViewInit(){
    // this.config = {
    //   slidesPerView: 1,
    //   spaceBetween: 0,         
    //   keyboard: true,
    //   navigation: true,
    //   pagination: this.pagination,
    //   grabCursor: true,        
    //   loop: false,
    //   preloadImages: false,
    //   lazy: true,     
    //   autoplay: {
    //     delay: 6000,
    //     disableOnInteraction: false
    //   },
    //   speed: 500,
    //   effect: "slide"
    // }
   
  }

}