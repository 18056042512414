'use strict';
export const CORE_API: string = 'https://api.markpedia.com/api';
export const _API: string = 'https://api.markpedia.com/api';
export const BLOG_API: string = 'https://api.markpedia.com/chat';

export const _URL_REGISTER: string = _API + '/auth/signup';
export const _URL_SMART_AUTH: string = _API + '/auth/msignup';
export const _URL_FORGOT_PASSWORD: string = CORE_API + '/password_reset';


