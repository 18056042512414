<ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage === 1">
      <a class="page-link" (click)="gotoPage(1)" aria-label="Première">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === 1">
      <a class="page-link" (click)="gotoPage(currentPage - 1)" aria-label="Précédente">
        <span aria-hidden="true">&lt;</span>
      </a>
    </li>

    <li class="page-item" *ngIf="currentPage - halfLimit > 1">
        <a class="page-link" (click)="gotoPage(1)" aria-label="Première">1</a>
      </li>
      <li class="page-item" *ngIf="currentPage - halfLimit > 2">
        <span class="page-link">...</span>
      </li>
      <li class="page-item" *ngFor="let page of visiblePages" [class.active]="page === currentPage">
        <a class="page-link" (click)="gotoPage(page)">{{ page }}</a>
      </li>
      <li class="page-item" *ngIf="currentPage + halfLimit < totalPages - 1">
        <span class="page-link">...</span>
      </li>
      <li class="page-item" *ngIf="currentPage + halfLimit < totalPages">
        <a class="page-link" (click)="gotoPage(totalPages)" aria-label="Dernière">{{ totalPages }}</a>
      </li>

    
    <li class="page-item" [class.disabled]="currentPage === totalPages">
      <a class="page-link" (click)="gotoPage(currentPage + 1)" aria-label="Suivante">
        <span aria-hidden="true">&gt;</span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === totalPages">
      <a class="page-link" (click)="gotoPage(totalPages)" aria-label="Dernière">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>