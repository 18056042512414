import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core'; 
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

export interface DialogData {
    animal: string;
    name: string;
  }

@Component({
    selector: 'app-search-image',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
    standalone: true,
    imports: [MatDialogModule, FormsModule, ReactiveFormsModule, MatButtonModule]
})
  export class SearchComponent  {
    public form!: FormGroup;
    srcResult!: any;
    imageSrc!: any;
    file!: File;
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<SearchComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
    onFileChange(event: any) {
      const reader = new FileReader();
       if(event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        
        this.file = file;
        }
  
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
  
          const reader = new FileReader();
          reader.onload = e => this.imageSrc = reader.result;
  
          reader.readAsDataURL(file);
      }
      }

      onFileSelected() {
        const inputNode: any = document.querySelector('#file');
      
        if (typeof (FileReader) !== 'undefined') {
          const reader = new FileReader();
      
          reader.onload = (e: any) => {
            this.srcResult = e.target.result;
          };  
          reader.readAsArrayBuffer(inputNode.files[0]);
        }
      }
    // gotoTrack(){
    //     this.router.navigate(['/search'])
    // }

    public gotoTrack(){
      // //console(this.form.value);
      const formData: FormData = new FormData();
   
       
        if (this.file != null) {
          formData.append("image", this.file, this.file.name);
        }else{
          formData.append("image", "");
        }
        this.dialogRef.close();
        this.router.navigate(['/products/search',formData])
       
      
      
     
      // if(this.form.valid){
      //   this.dialogRef.close(this.form.value);
      // }
    }
}