<div *ngIf="products" class="new-products-content">
    <div  class=" new-products-content-item" *ngFor="let prod of products">
                    
                    
        <div class="wsk-cp-product">
          <div class="product-img">
            <div class="product-img-box">
              <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images?.path" alt="Product"   width="100%"   />
            </a>
            </div>
            
        </div>
          <!-- <div class="wsk-cp-img">
            <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images?.path" alt="Product"   width="100%"   />
            </a>
          </div> -->
          <div class="wsk-cp-text" style="margin-top: 10px;">
            
            
            <div class="title-product" style="margin-top: 50px;" style="text-overflow: ellipsis;">
              
              <a style="color: black;" routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <h4 style="text-overflow: ellipsis;">{{truncate(prod?.my_product_flat?.name, 30)}}</h4> 
            </a>

            <div class="card-footer">
                <div class="wcf-left">
                  <app-rating [rating]="3.5"></app-rating>
                </div>

                  <div class="wcf-right">
                    {{tempsEcoule(prod?.created_at)}}
                  </div>
            </div>
            
          
              
              
              
            </div>
            
            
            <div class="card-footer product-footer">
              <div class="wcf-left"><span class="price">{{currency?.symbol}} {{prod.price* (currency?.value || 1) | number : '1.1-2' }}</span></div>
              <div class="wcf-right"><a style="cursor: pointer;" (click)="addToCart(prod)" class="buy-btn"><i class="fa fa-shopping-cart"></i></a></div>
            </div>
          </div>
        </div>
      </div>

     
</div>