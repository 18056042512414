import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgIf, NgFor } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { Data, AppService } from '../../../app.service';
import { Product, cartitem } from "../../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RatingComponent } from '../../rating/rating.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-new-product-list',
    templateUrl: './new-product-list.component.html',
    styleUrls: ['./new-product-list.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatTooltipModule, RatingComponent, MatButtonModule, MatIconModule, DecimalPipe],
})
export class NewProductListComponent implements OnInit {

  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;

  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')!); 
  @Input() product!: Product;
  @Input() type!: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  constructor(public authService: AuthenticationService,public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router, public snackBar: MatSnackBar) { 
    this.settings = this.appSettings.settings;
    this.authService?.currency$.subscribe((currency: any) =>{
      this.currency=currency
   })
  }

  ngOnInit() {
    if(this.product){
      if(this.product.cartCount > 0){
        this.count = this.product.cartCount;
      }
    }  
    this.layoutAlign(); 
   }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    }
  }

  public openProductDialog(product: any){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]); 
      }
    });
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }



  public increment(count: any){
    if(this.count < this.product.availibilityCount){
      this.count++;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
      this.changeQuantity(obj);
    }
    else{
      this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }    
  }

  public decrement(count: any){
    if(this.count > 1){
      this.count--;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
      this.changeQuantity(obj);
    }
  }

  public addToCompare(product:Product){
    this.appService.addToCompare(product);
  }

  public addToWishList(product:Product){
    this.appService.addToWishList(product);
  }

  public addToCart(product: Product): boolean {
    let currentProduct = this.appService.Data.cartList.find(item => item?.product_id?.id === product.id);
  
    if (currentProduct) {
      if ((currentProduct.cartCount + this.count) <= product?.stock) {
        product.cartCount = currentProduct.cartCount + this.count;
      } else {
        this.snackBar.open('You can not add more items than available. In stock ' + product?.stock + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    } else {
      product.cartCount = this.count;
    }
  
    this.appService.addToCart(product);
  
    return true; // Add this line to indicate successful addition to the cart
  }

  public openProductsDialog(event: any){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value: any){
      this.onQuantityChange.emit(value);
  }

}
