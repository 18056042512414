import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {User} from './user'
// import { any } from '../interfaces/user';
import { config } from 'src/config/local';
import * as URLS from 'src/app/constants';
interface Currency {
  base: boolean;
  currency: string;
  symbol: string;
  value?: any;
}
@Injectable({
  providedIn: 'root'
})

// @Injectable()
export class AuthenticationService {
  public host: string = config.apiUrl;
  rabbitSubscription!: Subscription;
  rabbitpersonnalSubscription!: Subscription;

  isAuth$ = new BehaviorSubject<boolean>(false);
  token!: string;
  userId!: string;
  pseudo!: string;
  activemail!: string;
  firstname: string = '';
  imageUrl: string = '';

  userinfo: any;
  isLoadingOnce: boolean = false;
  // private userOnceSubject$ = new BehaviorSubject<boolean>(this.userinfo);
  // userOnceChanged$ = this.userOnceSubject$.asObservable();

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public configObservable = new Subject<any>();
  public categories$ = new Subject<any[]>();
  public currency$ = new Subject<Currency>();
  public currencies$ = new Subject<any[]>();

  // baseUrl = "http://localhost:8000/"
   baseUrl =  `${config.apiUrlImage}/`
  //  baseUrl = "http://localhost:8000/"

   //private currentUserSubject = new BehaviorSubject<any>({} as any);
   //public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

   loginStatus = new BehaviorSubject<boolean>(false);

  private countryCurrencyMap: any = {
    "AF": "AFN", // Afghanistan - Afghani
    "AL": "ALL", // Albanie - Lek
    "DZ": "DZD", // Algérie - Dinar algérien
    "AS": "USD", // Samoa américaines - Dollar américain
    "AD": "EUR", // Andorre - Euro
    "AO": "AOA", // Angola - Kwanza
    "AI": "XCD", // Anguilla - Dollar des Caraïbes orientales
    "AQ": "NONE", // Antarctique - Aucune monnaie officielle
    "AG": "XCD", // Antigua-et-Barbuda - Dollar des Caraïbes orientales
    "AR": "ARS", // Argentine - Peso argentin
    "AM": "AMD", // Arménie - Dram arménien
    "AW": "AWG", // Aruba - Florin d'Aruba
    "AU": "AUD", // Australie - Dollar australien
    "AT": "EUR", // Autriche - Euro
    "AZ": "AZN", // Azerbaïdjan - Manat azéri
    "BS": "BSD", // Bahamas - Dollar bahaméen
    "BH": "BHD", // Bahreïn - Dinar bahreïni
    "BD": "BDT", // Bangladesh - Taka
    "BB": "BBD", // Barbade - Dollar de Barbade
    "BY": "BYN", // Biélorussie - Rouble biélorusse
    "BE": "EUR", // Belgique - Euro
    "BZ": "BZD", // Belize - Dollar de Belize
    "BJ": "XOF", // Bénin - Franc CFA
    "BM": "BMD", // Bermudes - Dollar bermudien
    "BT": "BTN", // Bhoutan - Ngultrum
    "BO": "BOB", // Bolivie - Boliviano
    "BQ": "USD", // Bonaire, Saint-Eustache et Saba - Dollar américain
    "BA": "BAM", // Bosnie-Herzégovine - Mark convertible
    "BW": "BWP", // Botswana - Pula
    "BR": "BRL", // Brésil - Réal brésilien
    "IO": "USD", // Territoire britannique de l'océan Indien - Dollar américain
    "VG": "USD", // Îles Vierges britanniques - Dollar américain
    "BN": "BND", // Brunei - Dollar de Brunei
    "BG": "BGN", // Bulgarie - Lev bulgare
    "BF": "XOF", // Burkina Faso - Franc CFA
    "BI": "BIF", // Burundi - Franc burundais
    "CV": "CVE", // Cap-Vert - Escudo cap-verdien
    "KH": "KHR", // Cambodge - Riel cambodgien
    "CM": "XAF", // Cameroun - Franc CFA
    "CA": "CAD", // Canada - Dollar canadien
    "KY": "KYD", // Îles Caïmans - Dollar des Îles Caïmans
    "CF": "XAF", // République centrafricaine - Franc CFA
    "TD": "XAF", // Tchad - Franc CFA
    "CL": "CLP", // Chili - Peso chilien
    "CN": "CNY", // Chine - Yuan chinois
    "CX": "AUD", // Île Christmas - Dollar australien
    "CC": "AUD", // Îles Cocos (Keeling) - Dollar australien
    "CO": "COP", // Colombie - Peso colombien
    "KM": "KMF", // Comores - Franc comorien
    "CG": "XAF", // Congo-Brazzaville - Franc CFA
    "CD": "CDF", // République démocratique du Congo - Franc congolais
    "CK": "NZD", // Îles Cook - Dollar néo-zélandais
    "CR": "CRC", // Costa Rica - Colón costaricien
    "HR": "HRK", // Croatie - Kuna croate
    "CU": "CUP", // Cuba - Peso cubain
    "CW": "ANG", // Curaçao - Florin antillais
    "CY": "EUR", // Chypre - Euro
    "CZ": "CZK", // République tchèque - Couronne tchèque
    "CI": "XOF", // Côte d'Ivoire - Franc CFA
    "DK": "DKK", // Danemark - Couronne danoise
    "DJ": "DJF", // Djibouti - Franc djiboutien
    "DM": "XCD", // Dominique - Dollar des Caraïbes orientales
    "DO": "DOP", // République dominicaine - Peso dominicain
    "EC": "USD", // Équateur - Dollar américain
    "EG": "EGP", // Égypte - Livre égyptienne
    "SV": "USD", // Salvador - Dollar américain
    "GQ": "XAF", // Guinée équatoriale - Franc CFA
    "ER": "ERN", // Érythrée - Nakfa érythréen
    "EE": "EUR", // Estonie - Euro
    "ET": "ETB", // Éthiopie - Birr éthiopien
    "FK": "FKP", // Îles Malouines - Livre des îles Malouines
    "FO": "DKK", // Îles Féroé - Couronne danoise
    "FJ": "FJD", // Fidji - Dollar fidjien
    "FI": "EUR", // Finlande - Euro
    "FR": "EUR", // France - Euro
    "GF": "EUR", // Guyane française - Euro
    "PF": "XPF", // Polynésie française - Franc Pacifique
    "TF": "EUR", // Terres australes françaises - Euro
    "GA": "XAF", // Gabon - Franc CFA
    "GM": "GMD", // Gambie - Dalasi gambien
    "GE": "GEL", // Géorgie - Lari géorgien
    "DE": "EUR", // Allemagne - Euro
    "GH": "GHS", // Ghana - Cedi
    "GI": "GIP", // Gibraltar - Livre de Gibraltar
    "GR": "EUR", // Grèce - Euro
    "GL": "DKK", // Groenland - Couronne danoise
    "GD": "XCD", // Grenade - Dollar des Caraïbes orientales
    "GP": "EUR", // Guadeloupe - Euro
    "GU": "USD", // Guam - Dollar américain
    "GT": "GTQ", // Guatemala - Quetzal guatémaltèque
    "GG": "GBP", // Guernesey - Livre sterling
    "GN": "GNF", // Guinée - Franc guinéen
    "GW": "XOF", // Guinée-Bissau - Franc CFA
    "GY": "GYD", // Guyana - Dollar guyanien
    "HT": "HTG", // Haïti - Gourde haïtienne
    "HM": "AUD", // Îles Heard-et-MacDonald - Dollar australien
    "VA": "EUR", // Vatican - Euro
    "HN": "HNL", // Honduras - Lempira hondurien
    "HK": "HKD", // Hong Kong - Dollar de Hong Kong
    "HU": "HUF", // Hongrie - Forint hongrois
    "IS": "ISK", // Islande - Couronne islandaise
    "IN": "INR", // Inde - Roupie indienne
    "ID": "IDR", // Indonésie - Roupie indonésienne
    "IR": "IRR", // Iran - Rial iranien
    "IQ": "IQD", // Irak - Dinar irakien
    "IE": "EUR", // Irlande - Euro
    "IM": "GBP", // Île de Man - Livre sterling
    "IL": "ILS", // Israël - Shekel israélien
    "IT": "EUR", // Italie - Euro
    "JM": "JMD", // Jamaïque - Dollar jamaïcain
    "JP": "JPY", // Japon - Yen japonais
    "JE": "GBP", // Jersey - Livre sterling
    "JO": "JOD", // Jordanie - Dinar jordanien
    "KZ": "KZT", // Kazakhstan - Tenge kazakh
    "KE": "KES", // Kenya - Shilling kényan
    "KI": "AUD", // Kiribati - Dollar australien
    "KW": "KWD", // Koweït - Dinar koweïtien
    "KG": "KGS", // Kirghizistan - Som kirghiz
    "LA": "LAK", // Laos - Kip laotien
    "LV": "EUR", // Lettonie - Euro
    "LB": "LBP", // Liban - Livre libanaise
    "LS": "LSL", // Lesotho - Loti lesothien
    "LR": "LRD", // Liberia - Dollar libérien
    "LY": "LYD", // Libye - Dinar libyen
    "LI": "CHF", // Liechtenstein - Franc suisse
    "LT": "EUR", // Lituanie - Euro
    "LU": "EUR", // Luxembourg - Euro
    "MO": "MOP", // Macao - Pataca de Macao
    "MK": "MKD", // Macédoine du Nord - Denar macédonien
    "MG": "MGA", // Madagascar - Ariary malgache
    "MW": "MWK", // Malawi - Kwacha malawien
    "MY": "MYR", // Malaisie - Ringgit malaisien
    "MV": "MVR", // Maldives - Rufiyaa maldivien
    "ML": "XOF", // Mali - Franc CFA
    "MT": "EUR", // Malte - Euro
    "MH": "USD", // Îles Marshall - Dollar américain
    "MQ": "EUR", // Martinique - Euro
    "MR": "MRU", // Mauritanie - Ouguiya mauritanien
    "MU": "MUR", // Maurice - Roupie mauricienne
    "YT": "EUR", // Mayotte - Euro
    "MX": "MXN", // Mexique - Peso mexicain
    "FM": "USD", // Micronésie - Dollar américain
    "MD": "MDL", // Moldavie - Leu moldave
    "MC": "EUR", // Monaco - Euro
    "MN": "MNT", // Mongolie - Tugrik mongol
    "ME": "EUR", // Monténégro - Euro
    "MS": "XCD", // Montserrat - Dollar des Caraïbes orientales
    "MA": "MAD", // Maroc - Dirham marocain
    "MZ": "MZN", // Mozambique - Metical mozambicain
    "MM": "MMK", // Myanmar - Kyat birman
    "NA": "NAD", // Namibie - Dollar namibien
    "NR": "AUD", // Nauru - Dollar australien
    "NP": "NPR", // Népal - Roupie népalaise
    "NL": "EUR", // Pays-Bas - Euro
    "NC": "XPF", // Nouvelle-Calédonie - Franc Pacifique
    "NZ": "NZD", // Nouvelle-Zélande - Dollar néo-zélandais
    "NI": "NIO", // Nicaragua - Córdoba nicaraguayen
    "NE": "XOF", // Niger - Franc CFA
    "NG": "NGN", // Nigeria - Naira
    "NU": "NZD", // Niue - Dollar néo-zélandais
    "NF": "AUD", // Île Norfolk - Dollar australien
    "KP": "KPW", // Corée du Nord - Won nord-coréen
    "MP": "USD", // Îles Mariannes du Nord - Dollar américain
    "NO": "NOK", // Norvège - Couronne norvégienne
    "OM": "OMR", // Oman - Rial omanais
    "PK": "PKR", // Pakistan - Roupie pakistanaise
    "PW": "USD", // Palaos - Dollar américain
    "PS": "ILS", // Territoire palestinien - Shekel israélien
    "PA": "PAB", // Panama - Balboa panaméen
    "PG": "PGK", // Papouasie-Nouvelle-Guinée - Kina papouan
    "PY": "PYG", // Paraguay - Guaraní paraguayen
    "PE": "PEN", // Pérou - Sol péruvien
    "PH": "PHP", // Philippines - Peso philippin
    "PN": "NZD", // Pitcairn - Dollar néo-zélandais
    "PL": "PLN", // Pologne - Zloty polonais
    "PT": "EUR", // Portugal - Euro
    "PR": "USD", // Porto Rico - Dollar américain
    "QA": "QAR", // Qatar - Riyal qatari
    "RU": "RUB", // Russie - Rouble russe
    "RW": "RWF", // Rwanda - Franc rwandais
    "RE": "EUR", // Réunion - Euro
    "BL": "EUR", // Saint-Barthélemy - Euro
    "SH": "SHP", // Sainte-Hélène, Ascension et Tristan da Cunha - Livre de Sainte-Hélène
    "KN": "XCD", // Saint-Christophe-et-Niévès - Dollar des Caraïbes orientales
    "LC": "XCD", // Sainte-Lucie - Dollar des Caraïbes orientales
    "MF": "EUR", // Saint-Martin (partie française) - Euro
    "PM": "EUR", // Saint-Pierre-et-Miquelon - Euro
    "VC": "XCD", // Saint-Vincent-et-les-Grenadines - Dollar des Caraïbes orientales
    "WS": "WST", // Samoa - Tala samoan
    "SM": "EUR", // Saint-Marin - Euro
    "ST": "STN", // Sao Tomé-et-Principe - Dobra sao-toméen
    "SA": "SAR", // Arabie saoudite - Riyal saoudien
    "SN": "XOF", // Sénégal - Franc CFA
    "RS": "RSD", // Serbie - Dinar serbe
    "SC": "SCR", // Seychelles - Roupie des Seychelles
    "SL": "SLL", // Sierra Leone - Leone sierra-léonais
    "SG": "SGD", // Singapour - Dollar de Singapour
    "SX": "ANG", // Saint-Martin (partie néerlandaise) - Florin antillais
    "SK": "EUR", // Slovaquie - Euro
    "SI": "EUR", // Slovénie - Euro
    "SB": "SBD", // Îles Salomon - Dollar des Îles Salomon
    "SO": "SOS", // Somalie - Shilling somalien
    "ZA": "ZAR", // Afrique du Sud - Rand
    "GS": "GBP", // Géorgie du Sud et les Îles Sandwich du Sud - Livre sterling
    "KR": "KRW", // Corée du Sud - Won sud-coréen
    "SS": "SSP", // Soudan du Sud - Livre sud-soudanaise
    "ES": "EUR", // Espagne - Euro
    "LK": "LKR", // Sri Lanka - Roupie srilankaise
    "SD": "SDG", // Soudan - Livre soudanaise
    "SR": "SRD", // Suriname - Dollar surinamien
    "SJ": "NOK", // Svalbard et Jan Mayen - Couronne norvégienne
    "SE": "SEK", // Suède - Couronne suédoise
    "CH": "CHF", // Suisse - Franc suisse
    "SY": "SYP", // Syrie - Livre syrienne
    "TW": "TWD", // Taïwan - Dollar taïwanais
    "TJ": "TJS", // Tadjikistan - Somoni tadjik
    "TZ": "TZS", // Tanzanie - Shilling tanzanien
    "TH": "THB", // Thaïlande - Baht thaïlandais
    "TL": "USD", // Timor-Leste - Dollar américain
    "TG": "XOF", // Togo - Franc CFA
    "TK": "NZD", // Tokelau - Dollar néo-zélandais
    "TO": "TOP", // Tonga - Pa'anga tongan
    "TT": "TTD", // Trinité-et-Tobago - Dollar de Trinité-et-Tobago
    "TN": "TND", // Tunisie - Dinar tunisien
    "TR": "TRY", // Turquie - Livre turque
    "TM": "TMT", // Turkménistan - Manat turkmène
    "TC": "USD", // Îles Turks-et-Caïcos - Dollar américain
    "TV": "AUD", // Tuvalu - Dollar australien
    "UG": "UGX", // Ouganda - Shilling ougandais
    "UA": "UAH", // Ukraine - Hryvnia ukrainienne
    "AE": "AED", // Émirats arabes unis - Dirham des Émirats arabes unis
    "GB": "GBP", // Royaume-Uni - Livre sterling
    "UM": "USD", // Îles mineures éloignées des États-Unis - Dollar américain
    "US": "USD", // États-Unis - Dollar américain
    "UY": "UYU", // Uruguay - Peso uruguayen
    "UZ": "UZS", // Ouzbékistan - Som ouzbek
    "VU": "VUV", // Vanuatu - Vatu vanuatuan
    "VE": "VES", // Venezuela - Bolívar vénézuélien
    "VN": "VND", // Viêt Nam - Dong vietnamien
    "VI": "USD", // Îles Vierges des États-Unis - Dollar américain
    "WF": "XPF", // Wallis-et-Futuna - Franc Pacifique
    "EH": "MAD", // Sahara occidental - Dirham marocain
    "YE": "YER", // Yémen - Rial yéménite
    "ZM": "ZMW", // Zambie - Kwacha zambien
    "ZW": "ZWL" // Zimbabwe - Dollar zimbabwéen
  };

   forgotpassword(query: any): any {


    return this.httpClient.post<any>(URLS._URL_FORGOT_PASSWORD, query)
        .pipe(map(user => {
            return user;
        }));
}
// fetchInfo(query): any {


//     return this.httpClient.post<any>(URLS._URL_INFO_USER, query)
//         .pipe(map(user => {
//             this.userinfo = user;
//             this.userOnceSubject$.next(this.userinfo);
//             return user;
//         }));
// }
// codeverification(query): any {


//     return this.httpClient.post<any>(URLS._URL_CODE_VERIFICATION, query)
//         .pipe(map(user => {
//             // //console(user)
//             // login successful if there's a jwt token in the response
//             return user;
//         }));
// }
   updateOnCache(user: any){
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
   }
   constructor(private httpClient: HttpClient)
  { 
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
    // const current_currency = localStorage.getItem('MarkpediaDevise')
    // //console("current_currency", current_currency)
    // if (JSON.parse(current_currency)) {
    //   this.currency$.next(JSON.parse(current_currency))
    // }else{
    //   const currency: Currency = {
    //     currency: 'USD',
    //     symbol: '$',
    //     base: true,
    //     value: 1
    //   }
    //   localStorage.setItem('MarkpediaDevise', JSON.stringify(currency))
    //   this.currency$.next(currency)
    // }
    const user_devise = JSON.parse(localStorage.getItem('MarkpediaDevise')!)
    if (user_devise) {
      this.currency$.next(user_devise)
    }else {
      this.getipinfo()
    }

    
   
  //   if (this.currentUserValue) {
  //     this.token = this.currentUserValue.token;
  //     this.userId = this.currentUserValue.userId;
  //     this.pseudo = this.currentUserValue.pseudo;
  //     this.imageUrl = this.currentUserValue.imageUrl;
  //     this.isAuth$.next(true);

  //    // this.initRabbitWatch();
  // }
  }

  getipinfo(){
    
    const myurl = `https://ipinfo.io?token=73ea2317716633`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (response: any) => {
          console.log("getipinfo", response)
          if (response?.country in this.countryCurrencyMap) {
            const visitorCurrency = this.countryCurrencyMap[response?.country];
            console.log("Devise du pays du visiteur:", visitorCurrency);
            // const currency: Currency = {
            //   currency: visitorCurrency,
            //   symbol: visitorCurrency,
            //   base: true,
            //   value: 655.55
            // }
            // localStorage.setItem('MarkpediaDevise', JSON.stringify(currency))
            // this.currency$.next(currency)
            const myurl = `https://openexchangerates.org/api/latest.json?app_id=d73ddd997bda42dab524aa930edd69b5&symbols=${visitorCurrency}`;
            return new Promise<void>((resolve, reject) => {
              this.httpClient.get(myurl).subscribe(
                (resp: any) => {
                  const currency: Currency = {
                    currency: visitorCurrency,
                    symbol: visitorCurrency,
                    base: true,
                    value: Object.values(resp?.rates)[0]
                  }
                  localStorage.setItem('MarkpediaDevise', JSON.stringify(currency))
                  this.currency$.next(currency)
                  console.log("currency du pays du visiteur:", currency);
                    // resolve(user);
                },
                (error) => {
                  reject(error);
                }
              );
            });
            // Vous pouvez maintenant utiliser 'visitorCurrency' pour effectuer les conversions de prix en fonction de la devise du visiteur
          } else {
            console.log("Devise du pays du visiteur non trouvée.");
            // Vous pouvez choisir une devise par défaut ou proposer une option pour que l'utilisateur choisisse manuellement la devise.
          }
            // resolve(response);
            return;
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public login(url: string, user: any): Observable<any> {
    return this.httpClient
      .post<any>(this.host+url, user, {responseType: 'json'})
      .pipe(map((response: any) => {
        //console(response);
        this.loginStatus.next(true);
        //console("SUCCES LOGIN");
        localStorage.setItem("markpediausersessionid",JSON.stringify(response))
        this.currentUserSubject.next(response);

                   
        //console(this.currentUserSubject);

        return response;
      }));
  }

  public postSignUp(url: string, user: any): Observable<any> {
    return this.httpClient
      .post<any>(this.host+url, user)
      .pipe(map((response: any) => {
        //console(response);

        // localStorage.setItem("markpediausersessionid",JSON.stringify(response))
        this.currentUserSubject.next(response);

                   
        //console(this.currentUserSubject);

        return response;
      }));
  }

  
  
  createNewUser(url: string, user: any) {

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const myurl = `${this.baseUrl}${url}`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(myurl, user, options)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  upDateUser(url: string, user: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.put(
        myurl, user)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  getsCurrencyDevice(devise: string) {
    const myurl = `https://openexchangerates.org/api/latest.json?app_id=d73ddd997bda42dab524aa930edd69b5&symbols=${devise}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getsEntite(url: string) {
    const myurl = `${this.baseUrl}${url}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getsEntites(url: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getEntity(url: string, entity: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl, entity)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  confirmAccount(url: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response: any) => {
        resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotPassword(url: string, email: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl,
        {email : email})
        .subscribe(
          (response: any) => {
           resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUserById(url: string, id: string) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  deleteEntityById(url: string, id: string) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.delete(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

 

 

}