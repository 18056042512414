<div *ngIf="products" >
    <div class="slide-mobile"   style="margin-top: 30px;">
        <div class="" *ngFor="let dealsdays of products ">
          <a  routerLink="/products/{{dealsdays?.product_id.my_product_flat.id}}/{{removeSlash(dealsdays?.product_id?.my_product_flat?.name)}}" target="_self">
          <div class="">
            <img src="{{dealsdays?.product_id.images?.path}}" alt="">   
            <div>
                {{ truncate(dealsdays?.product_id?.my_product_flat?.name, 25)}}
            </div>
            <div class="card-footer">
                <div class="wcf-left">
                    <app-rating [rating]="3.5"></app-rating>
                </div>
                <div class="wcf-right">
                    {{tempsEcoule(dealsdays?.product_id?.created_at)}}
                  </div>
            </div>
           
            <!-- <div class="price" *ngIf="dealsdays?.dealy_deals_price">
                {{currency?.symbol}} {{dealsdays?.dealy_deals_price* (currency?.value || 1)  | number : '1.1-2' }}
            </div> -->
            <div class="price" *ngIf="dealsdays?.product_id?.price">
                {{currency?.symbol}} {{dealsdays?.product_id?.price* (currency?.value || 1)  | number : '1.1-2' }}
            </div>
          </div>
          </a>
        </div>
        
    </div>
</div>